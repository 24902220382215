import Fab from '@mui/material/Fab';
import styled from '@mui/material/styles/styled';
import { Theme } from '@mui/system';

import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'app/AppStateStore';
import { navbarToggle, navbarToggleMobile } from 'app/store/fuse/navbarSlice';
import { selectLayoutConfig } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const NavbarToggleFab: FC<{ className?: string }> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('landingNavigation');

  const isMobile = useThemeMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  const config = useSelector(selectLayoutConfig);

  return (
    <Root
      title={t('show_navigation')}
      placement={config.navbar.position === 'left' ? 'right' : 'left'}
      position={config.navbar.position}
    >
      <Fab
        className={clsx('button', props.className)}
        onClick={(ev) =>
          dispatch(isMobile ? navbarToggleMobile() : navbarToggle())
        }
        disableRipple
      >
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <FuseSvgIcon color="action" className="button-icon">
          heroicons-outline:view-list
        </FuseSvgIcon>
      </Fab>
    </Root>
  );
};

const Root = styled(Tooltip)(
  ({ theme, position }: { theme?: Theme; position: 'left' | 'right' }) => ({
    '& > .button': {
      height: 40,
      position: 'absolute',
      zIndex: 99,
      top: 12,
      width: 24,
      borderRadius: 38,
      padding: 8,
      backgroundColor: theme!.palette.background.paper,
      // @ts-ignore
      transition: theme.transitions.create(
        ['background-color', 'border-radius', 'width', 'min-width', 'padding'],
        {
          // @ts-ignore
          easing: theme.transitions.easing.easeInOut,
          // @ts-ignore
          duration: theme.transitions.duration.shorter,
        }
      ),
      '&:hover': {
        width: 52,
        paddingLeft: 8,
        paddingRight: 8,
      },

      '& > .button-icon': {
        fontSize: 18,
        // @ts-ignore
        transition: theme.transitions.create(['transform'], {
          // @ts-ignore
          easing: theme.transitions.easing.easeInOut,
          // @ts-ignore
          duration: theme.transitions.duration.short,
        }),
      },

      ...(position === 'left' && {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        paddingLeft: 4,
        left: 0,
      }),

      ...(position === 'right' && {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        paddingRight: 4,
        right: 0,
        '& > .button-icon': {
          transform: 'rotate(-180deg)',
        },
      }),
    },
  })
);

export default NavbarToggleFab;
