import { useDeepCompareEffect } from '@fuse/hooks';
import { useDispatch, useSelector } from 'app/AppStateStore';
import { ProducerPageRoutes } from 'app/configs/routesConfig';
import {
  selectFuseSettings,
  selectRoutes,
  setSettings,
} from 'app/store/fuse/settingsSlice';
import isEqual from 'lodash/fp/isEqual';
import merge from 'lodash/fp/merge';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  RouteMatch,
  RouteObject,
  matchRoutes,
  useLocation,
} from 'react-router-dom';

export type FuseRouteObjectType = RouteObject & {
  settings?: any;
};

export type FuseRouteMatchType = RouteMatch & {
  route: FuseRouteObjectType;
};

type FuseLayoutProps = {
  layouts: any;
  children?: React.ReactNode;
};

/**
 * FuseLayout
 * React frontend component in a React project that is used for layouting the user interface. The component
 * handles generating user interface settings related to current routes, merged with default settings, and uses
 * the new settings to generate layouts.
 */
function FuseLayout(props: FuseLayoutProps) {
  const { layouts } = props;
  const dispatch = useDispatch();
  const settings = useSelector(selectFuseSettings);
  const routes = useSelector(selectRoutes);

  const location = useLocation();
  const { pathname } = location;

  const matchedRoutes = matchRoutes(routes as RouteObject[], pathname);
  const matched = matchedRoutes ? matchedRoutes[0] : false;

  const producerMatchedRoutes = matchRoutes(ProducerPageRoutes, pathname);

  let layoutType = 'landingLayout';
  if (producerMatchedRoutes) {
    layoutType = 'adminLayout';
  }

  const newSettings = useRef(null);

  const shouldAwaitRender = useCallback(() => {
    let _newSettings;
    /**
     * On Path changed
     */
    // if (prevPathname !== pathname) {
    if (matched && (matched.route as any).settings) {
      /**
       * if matched route has settings
       */

      const routeSettings = (matched.route as any).settings;

      _newSettings = merge(settings, routeSettings);
    } else {
      _newSettings = newSettings.current;
    }

    if (!isEqual(newSettings.current, _newSettings)) {
      newSettings.current = _newSettings;
    }
  }, [settings, matched]);

  shouldAwaitRender();

  useDeepCompareEffect(() => {
    if (!isEqual(newSettings.current, settings)) {
      dispatch(setSettings(newSettings.current!));
    }
  }, [dispatch, newSettings.current, settings]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const Layout = useMemo(() => layouts[layoutType], [layouts, layoutType]);

  return isEqual(newSettings.current, settings) ? (
    <>
      <Layout {...props} />
    </>
  ) : null;
}

export default FuseLayout;
