import { type TPageConfig } from 'app/AppStateStore';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';
import { lazy } from 'react';

const AboutPage = lazy(() => import('./AboutPage'));

const AboutPageConfig: TPageConfig = {
  settings: {
    layout: themeLayoutConfigs['landingLayout'],
  },
  routes: [
    {
      path: 'about',
      element: <AboutPage />,
    },
  ],
};

export default AboutPageConfig;
