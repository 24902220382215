import { createTheme, getContrastRatio } from '@mui/material/styles';
import {
  TDispatchAction,
  TSettings,
  TStateStore,
  TThemeMode,
  useSelector,
} from 'app/AppStateStore';
import themeOptions, {
  TPaletteConfig,
  TThemeTypes,
  darkPaletteText,
  defaultThemeOptions,
  lightPaletteText,
  mustHaveThemeOptions,
} from 'app/configs/themesConfig';
import i18n, { TSupportedLanguages } from 'i18n';
import merge from 'lodash/merge';

export const useMainThemeLight = () => {
  return useTheme('light');
};

export const useMainThemeDark = () => {
  return useTheme('dark');
};

export const useContrastMainTheme = (bgColor: string) => {
  const isDark = (color: string) => getContrastRatio(color, '#ffffff') >= 3;

  return useTheme(isDark(bgColor) ? 'dark' : 'light');
};

export const useMainTheme = (themeMode?: TThemeMode) => {
  const themeType = useSelector<keyof TThemeTypes>(selectThemeType);
  const currentTheme = themeOptions.find(
    (theme) => theme.themeType === themeType
  );
  if (!currentTheme) {
    throw new Error('Theme not found');
  }

  const theme = currentTheme.section.main;

  const mainThemeMode = themeMode || theme.palette.mode;

  return useTheme(mainThemeMode);
};

export const useNavbarTheme = (themeMode?: TThemeMode) => {
  const themeType = useSelector<keyof TThemeTypes>(selectThemeType);
  const currentTheme = themeOptions.find(
    (theme) => theme.themeType === themeType
  );
  if (!currentTheme) {
    throw new Error('Theme not found');
  }

  const theme = currentTheme.section.navbar;
  const navbarThemeMode = themeMode || theme.palette.mode;

  return useTheme(navbarThemeMode);
};

export const useToolbarTheme = (themeMode?: TThemeMode) => {
  const themeType = useSelector<keyof TThemeTypes>(selectThemeType);
  const currentTheme = themeOptions.find(
    (theme) => theme.themeType === themeType
  );
  if (!currentTheme) {
    throw new Error('Theme not found');
  }

  const theme = currentTheme.section.toolbar;
  const toolbarThemeMode = themeMode || theme.palette.mode;

  return useTheme(toolbarThemeMode);
};

export const useFooterTheme = (themeMode?: TThemeMode) => {
  const themeType = useSelector<keyof TThemeTypes>(selectThemeType);
  const currentTheme = themeOptions.find(
    (theme) => theme.themeType === themeType
  );
  if (!currentTheme) {
    throw new Error('Theme not found');
  }

  const theme = currentTheme.section.footer;
  const footerThemeMode = themeMode || theme.palette.mode;

  return useTheme(footerThemeMode);
};

export const selectFuseSettings = ({ fuse }: TStateStore) => fuse.settings;

export const selectLayoutConfig = ({ fuse }: TStateStore) =>
  fuse.settings.layout;

export const selectRoutes = (store: TStateStore) => store.routes;

export const selectSupportedLanguages = (store: TStateStore) =>
  store.supportedLanguages;

export const changeLanguage: TDispatchAction<TSupportedLanguages> =
  (newLanguage) => (store) => {
    i18n.changeLanguage(newLanguage);
    store.lng = newLanguage;
  };

export const setSettings: TDispatchAction<TSettings> =
  (newSettings) => (store) => {
    store.fuse.settings = newSettings;
  };

export const changeFuseTheme: TDispatchAction<keyof TThemeTypes> =
  (newTheme) => (store) => {
    store.fuse.settings.themeType = newTheme;
  };

const useTheme = (themeMode: TThemeMode) => {
  const direction = useSelector<'ltr' | 'rtl'>(selectDirection);
  const themeType = useSelector<keyof TThemeTypes>(selectThemeType);

  const currentTheme = themeOptions.find(
    (theme) => theme.themeType === themeType
  );
  if (!currentTheme) {
    throw new Error('Theme not found');
  }

  const theme = currentTheme.section.main;

  // We change the theme mode only if the theme mode in the settings
  // is different from the palette mode. This is needed to be able to override the palette
  // mode with the theme mode in the settings.
  if (themeMode !== theme.palette.mode) {
    return generateMuiTheme(changeThemeMode(themeType, themeMode), direction);
  }

  return generateMuiTheme(theme, direction);
};

const generateMuiTheme = (
  themePalette: TPaletteConfig,
  direction: 'ltr' | 'rtl'
) => {
  const data = merge(
    {},
    defaultThemeOptions,
    themePalette,
    mustHaveThemeOptions
  );

  const response = createTheme(
    // @ts-ignore
    merge({}, data, {
      mixins: extendThemeWithMixins(data),
      direction,
    })
  );
  return response;
};

const changeThemeMode = (themeType: keyof TThemeTypes, mode: TThemeMode) => {
  const modes = {
    dark: {
      palette: {
        mode: 'dark',
        divider: 'rgba(241,245,249,.12)',
        background: {
          paper: '#1E2125',
          default: '#121212',
        },
        text: darkPaletteText,
      },
    },
    light: {
      palette: {
        mode: 'light',
        divider: '#e2e8f0',
        background: {
          paper: '#FFFFFF',
          default: '#F7F7F7',
        },
        text: lightPaletteText,
      },
    },
  };

  const currentTheme = themeOptions.find(
    (theme) => theme.themeType === themeType
  );
  if (!currentTheme) {
    throw new Error('Theme not found');
  }

  const theme = currentTheme.section.main;

  return merge({}, theme, modes[mode]);
};

const extendThemeWithMixins = (obj: object) => {
  const theme = createTheme(obj);
  return {
    border: (width = 1) => ({
      borderWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderLeft: (width = 1) => ({
      borderLeftWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderRight: (width = 1) => ({
      borderRightWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderTop: (width = 1) => ({
      borderTopWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
    borderBottom: (width = 1) => ({
      borderBottomWidth: width,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    }),
  };
};

const selectThemeType = ({ fuse }: TStateStore) => {
  return fuse.settings.themeType;
};

const selectDirection = ({ fuse }: TStateStore) => fuse.settings.direction;
