import FuseLoading from '@fuse/core/FuseLoading';
import { TPageConfig } from 'app/AppStateStore';
import Error404PageConfig from 'app/pages/404/Error404PageConfig';
import AboutPageConfig from 'app/pages/about/AboutPageConfig';
import ConsumerAdminPageConfig from 'app/pages/admin/ConsumerPageConfig';
import ProducerAdminPageConfig from 'app/pages/admin/ProducerPageConfig';
import ConsumerPageConfig from 'app/pages/consumer/ConsumerPageConfig';
import ContactsPageConfig from 'app/pages/contacts/ContactsPageConfig';
import ForgotPasswordConfig from 'app/pages/forgot-password/ForgotPasswordConfig';
import HelpCenterPageConfig from 'app/pages/help-center/HelpCenterPageConfig';
import MailConfirmConfig from 'app/pages/mail-confirmation/MailConfirmConfig';
import MainPageConfig from 'app/pages/main/MainPageConfig';
import PrivacyPageConfig from 'app/pages/privacy/PrivacyPageConfig';
import ProducerPageConfig from 'app/pages/producer/ProducerPageConfig';
import RecipesPageConfig from 'app/pages/recipes/RecipesPageConfig';
import ResetPasswordConfig from 'app/pages/reset-password/ResetPasswordConfig';
import SignInConfig from 'app/pages/sign-in/SignInConfig';
import SignUpConfig from 'app/pages/sign-up/SignUpConfig';
import TermsPageConfig from 'app/pages/terms/TermsPageConfig';
import merge from 'lodash/fp/merge';
import { Navigate, RouteObject } from 'react-router-dom';

const CommonRoutes = [
  Error404PageConfig,
  SignInConfig,
  SignUpConfig,
  ForgotPasswordConfig,
  ResetPasswordConfig,
  MailConfirmConfig,
];
const LandingRoutes = [
  MainPageConfig,
  AboutPageConfig,
  ConsumerPageConfig,
  RecipesPageConfig,
  PrivacyPageConfig,
  TermsPageConfig,
  ContactsPageConfig,
  ProducerPageConfig,
  HelpCenterPageConfig,
];

const ProducerRoutes = [ProducerAdminPageConfig];
const ConsumerRoutes: TPageConfig[] = [];

const routeConfigs = [
  ...CommonRoutes,
  ...LandingRoutes,
  ...ProducerRoutes,
  ...ConsumerRoutes,
];

const generateRoutesFromConfigs = (configs: TPageConfig[]) => {
  let allRoutes: RouteObject[] = [];
  configs.forEach((config: TPageConfig) => {
    const routes = config.routes.map((route: RouteObject) => {
      const settings = merge({}, config.settings);

      return {
        ...route,
        settings,
      };
    });

    allRoutes = [...allRoutes, ...routes];
  });

  return allRoutes;
};

const routes: RouteObject[] = [
  ...generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    element: <Navigate to="/dashboard" />,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
];

export const LandingPageRoutes = generateRoutesFromConfigs([
  ...CommonRoutes,
  ...LandingRoutes,
]);

export const ConsumerPageRoutes = generateRoutesFromConfigs([
  ConsumerAdminPageConfig,
]);

export const ProducerPageRoutes = generateRoutesFromConfigs([
  ProducerAdminPageConfig,
]);

export default routes;
