import { type TPageConfig } from 'app/AppStateStore';
import { lazy } from 'react';

const MailConfirmationPage = lazy(() => import('./MailConfirmationPage'));
const MailConfirmationRequiredPage = lazy(
  () => import('./MailConfirmationRequiredPage')
);

const MailConfirmConfig: TPageConfig = {
  settings: {
    layout: {
      navbar: {
        display: false,
      },
      toolbar: {
        display: false,
      },
      footer: {
        display: false,
      },
      leftSidePanel: {
        display: false,
      },
      rightSidePanel: {
        display: false,
      },
    },
  },
  routes: [
    {
      path: 'auth/mail-confirmation-required',
      element: <MailConfirmationRequiredPage />,
    },
    {
      path: 'auth/mail-confirmation/:token',
      element: <MailConfirmationPage />,
    },
    {
      path: 'auth/mail-confirmation',
      element: <MailConfirmationPage />,
    },
  ],
};

export default MailConfirmConfig;
