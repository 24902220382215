import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/system';
import { useDispatch } from 'app/AppStateStore';
import { navbarToggle, navbarToggleMobile } from 'app/store/fuse/navbarSlice';
import { FC } from 'react';

const NavbarToggleButton: FC<
  { className?: string } & React.PropsWithChildren
> = ({
  children = (
    <FuseSvgIcon size={20} color="action">
      heroicons-outline:view-list
    </FuseSvgIcon>
  ),
  ...props
}) => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );
  // const settings = useSelector(selectFuseCurrentSettings);
  // const { layout: config } = settings;

  return (
    <IconButton
      className={props.className}
      color="inherit"
      size="small"
      onClick={(ev) => {
        if (isMobile) {
          dispatch(navbarToggleMobile());
        } else {
          dispatch(navbarToggle());
        }
      }}
    >
      {children}
    </IconButton>
  );
};

export default NavbarToggleButton;
