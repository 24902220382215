import { type TPageConfig } from 'app/AppStateStore';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';
import { lazy } from 'react';

const HelpCenterPage = lazy(() => import('./HelpCenterPage'));
const HelpCenterFaqs = lazy(() => import('./faqs/HelpCenterFaqs'));
const HelpCenterGuide = lazy(() => import('./guide/HelpCenterGuide'));
const GuideCategories = lazy(() => import('./guides/GuideCategories'));
const GuideCategory = lazy(() => import('./guides/GuideCategory'));
const HelpCenterGuides = lazy(() => import('./guides/HelpCenterGuides'));
const HelpCenterHome = lazy(() => import('./home/HelpCenterHome'));
const HelpCenterSupport = lazy(() => import('./support/HelpCenterSupport'));

const HelpCenterPageConfig: TPageConfig = {
  settings: {
    layout: themeLayoutConfigs['landingLayout'],
  },
  routes: [
    {
      path: 'help',
      element: <HelpCenterPage />,
      children: [
        {
          path: '',
          element: <HelpCenterHome />,
        },
        {
          path: 'faqs',
          element: <HelpCenterFaqs />,
        },
        {
          path: 'guides',
          element: <HelpCenterGuides />,
          children: [
            {
              path: '',
              element: <GuideCategories />,
            },
            {
              path: ':categorySlug',
              element: <GuideCategory />,
            },
            {
              path: ':categorySlug/:guideSlug',
              element: <HelpCenterGuide />,
            },
          ],
        },
        {
          path: 'support',
          element: <HelpCenterSupport />,
        },
      ],
    },
  ],
};

export default HelpCenterPageConfig;
