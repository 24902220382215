import { FC } from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

const SocialFollow: FC = () => (
  <Grid>
    <Typography component="span" className="ml-3 sm:ml-6">
      <LinkItem
        href="https://instagram.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon fontSize="large" />
      </LinkItem>
    </Typography>
    <Typography component="span" className="ml-3 sm:ml-6">
      <LinkItem
        href="https://facebook.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon fontSize="large" />
      </LinkItem>
    </Typography>
    <Typography component="span" className="ml-3 sm:ml-6">
      <LinkItem
        href="https://twitter.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterIcon fontSize="large" />
      </LinkItem>
    </Typography>
  </Grid>
);

const LinkItem = styled(Link)(({ theme }) => ({
  backgroundColor: 'transparent!important',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  border: 'none!important',
}));

export default SocialFollow;
