import { TDispatchAction, TStateStore } from 'app/AppStateStore';

export const selectFuseDialogState = ({ fuse }: TStateStore) =>
  fuse.dialog.state;

export const selectFuseDialogOptions = ({ fuse }: TStateStore) =>
  fuse.dialog.options;

export const openDialog: TDispatchAction<void> = () => (store) => {
  store.fuse.dialog.state = true;
};

export const closeDialog: TDispatchAction<{}> = (payload) => (store) => {
  store.fuse.dialog.state = false;
  store.fuse.dialog.options = payload;
};
