import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomSelectProps {
  label: string;
  value: string | undefined;
  onChange: (event: SelectChangeEvent, field: string) => void;
  options: { value: string | number; label: string }[];
  variant?: 'outlined' | 'standard' | 'filled';
  name?: string;
  labelId?: string;
}

const CustomSelect: FC<CustomSelectProps> = ({
  label,
  value,
  onChange,
  options,
  variant,
  name,
  labelId,
}) => {
  const { t } = useTranslation('products');
  return (
    <FormControl sx={{ m: 1, width: 290 }}>
      <InputLabel id={labelId} variant={variant}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        value={value}
        onChange={(event) => onChange(event, label)}
        autoWidth
        label={label}
        name={name}
        variant={variant}
      >
        <MenuItem value="">
          <em> {t(`notSelected`)}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
