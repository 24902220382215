import { TDispatchAction, TStateStore } from 'app/AppStateStore';

export const selectFuseNavbarFoldedOpen = ({ fuse }: TStateStore) =>
  fuse.navbar.foldedOpen;
export const selectFuseNavbarMobileOpen = ({ fuse }: TStateStore) =>
  fuse.navbar.mobileOpen;
export const selectFuseNavbarOpen = ({ fuse }: TStateStore) => fuse.navbar.open;

export const navbarToggleFolded: TDispatchAction<void> = () => (store) => {
  store.fuse.navbar.foldedOpen = !store.fuse.navbar.foldedOpen;
};

export const navbarOpenFolded: TDispatchAction<void> = () => (store) => {
  store.fuse.navbar.foldedOpen = true;
};

export const navbarCloseFolded: TDispatchAction<void> = () => (store) => {
  store.fuse.navbar.foldedOpen = false;
};

export const navbarToggleMobile: TDispatchAction<void> = () => (store) => {
  store.fuse.navbar.mobileOpen = !store.fuse.navbar.mobileOpen;
};

export const navbarOpenMobile: TDispatchAction<void> = () => (store) => {
  store.fuse.navbar.mobileOpen = true;
};

export const navbarCloseMobile: TDispatchAction<void> = () => (store) => {
  store.fuse.navbar.mobileOpen = false;
};

export const navbarToggle: TDispatchAction<void> = () => (store) => {
  store.fuse.navbar.open = !store.fuse.navbar.open;
};

export const navbarOpen: TDispatchAction<void> = () => (store) => {
  store.fuse.navbar.open = true;
};

export const navbarClose: TDispatchAction<void> = () => (store) => {
  store.fuse.navbar.open = false;
};
