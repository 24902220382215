import Hidden from '@mui/material/Hidden';
import { styled, Theme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useDispatch, useSelector } from 'app/AppStateStore';
import {
  navbarCloseFolded,
  navbarCloseMobile,
  navbarOpenFolded,
  selectFuseNavbarFoldedOpen,
} from 'app/store/fuse/navbarSlice';
import {
  selectLayoutConfig,
  useNavbarTheme,
} from 'app/store/fuse/settingsSlice';
import NavbarFoldeddContent from './NavbarFoldedContent';

const navbarWidth = 280;

const NavbarFolded = () => {
  const dispatch = useDispatch();
  const config = useSelector(selectLayoutConfig);
  const navbarFoldedOpen = useSelector<boolean>(selectFuseNavbarFoldedOpen);
  const navbarMobileOpen = useSelector<boolean>(selectFuseNavbarFoldedOpen);
  const navbarTheme = useNavbarTheme();

  // const folded = !navbar.open;
  const { folded } = config.navbar;
  const foldedAndOpened = folded && navbarFoldedOpen;
  const foldedAndClosed = folded && !navbarFoldedOpen;

  return (
    <Root
      folded={folded}
      id="fuse-navbar"
      className="sticky top-0 h-screen shrink-0 z-20 shadow-5"
      theme={navbarTheme}
    >
      <Hidden lgDown>
        <StyledNavbar
          className="flex-col flex-auto"
          theme={navbarTheme}
          position={config.navbar.position}
          folded={folded}
          foldedAndOpened={foldedAndOpened}
          foldedAndClosed={foldedAndClosed}
          onMouseEnter={() => foldedAndClosed && dispatch(navbarOpenFolded())}
          onMouseLeave={() => foldedAndOpened && dispatch(navbarCloseFolded())}
        >
          <NavbarFoldeddContent className="NavbarStyle2-content" />
        </StyledNavbar>
      </Hidden>

      <Hidden lgUp>
        <StyledNavbarMobile
          classes={{
            paper: 'flex-col flex-auto h-full',
          }}
          anchor={config.navbar.position}
          variant="temporary"
          open={navbarMobileOpen}
          onClose={() => dispatch(navbarCloseMobile())}
          onOpen={() => {}}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavbarFoldeddContent className="NavbarStyle2-content" />
        </StyledNavbarMobile>
      </Hidden>
    </Root>
  );
};

const Root = styled('div')(
  ({ theme, folded }: { theme: Theme; folded: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    zIndex: 4,
    [theme.breakpoints.up('lg')]: {
      width: navbarWidth,
      minWidth: navbarWidth,
    },

    ...(folded && {
      [theme.breakpoints.up('lg')]: {
        width: 76,
        minWidth: 76,
      },
    }),
  })
);

const StyledNavbar = styled('div')(
  ({
    theme,
    position,
    folded,
    foldedAndOpened,
    foldedAndClosed: foldedandclosed,
  }: {
    theme: Theme;
    position: 'left' | 'right';
    folded: boolean;
    foldedAndOpened: boolean;
    foldedAndClosed: boolean;
  }) => ({
    minWidth: navbarWidth,
    width: navbarWidth,
    maxWidth: navbarWidth,
    maxHeight: '100%',
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),

    ...(position === 'left' && {
      left: 0,
    }),

    ...(position === 'right' && {
      right: 0,
    }),

    ...(folded && {
      position: 'absolute',
      width: 76,
      minWidth: 76,
      top: 0,
      bottom: 0,
    }),

    ...(foldedAndOpened && {
      width: navbarWidth,
      minWidth: navbarWidth,
    }),

    ...(foldedandclosed && {
      '& .NavbarStyle2-content': {
        '& .logo-icon': {
          width: 44,
          height: 44,
        },
        '& .logo-text': {
          opacity: 0,
        },
        '& .react-badge': {
          opacity: 0,
        },
        '& .fuse-list-item': {
          width: 56,
        },
        '& .fuse-list-item-text, & .arrow-icon, & .item-badge': {
          opacity: 0,
        },
        '& .fuse-list-subheader .fuse-list-subheader-text': {
          opacity: 0,
        },
        '& .fuse-list-subheader:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          minWidth: 16,
          borderTop: '2px solid',
          opacity: 0.2,
        },
        '& .collapse-children': {
          display: 'none',
        },
      },
    }),
  })
);

const StyledNavbarMobile = styled(SwipeableDrawer)(({ theme }) => ({
  '& > .MuiDrawer-paper': {
    minWidth: navbarWidth,
    width: navbarWidth,
    maxWidth: navbarWidth,
    maxHeight: '100%',
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default NavbarFolded;
