import { styled } from '@mui/material/styles';
import { FC, useState } from 'react';
import CategorySelect from './CategorySelect';
import CitySelect from './CitySelect';
import DeliverySelect from './DeliverySelect ';
import { TFormData } from './ProductFilter';

type TProductSelectProps = {
  initialFilters: TFormData;
  onUpdateFilters: (filters: TFormData) => void;
};

const ProductSelect: FC<TProductSelectProps> = ({
  initialFilters,
  onUpdateFilters,
}) => {
  const [formData, setFormData] = useState<TFormData>(initialFilters);

  const updateFormData = (key: keyof TFormData, value: string) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [key]: value };
      onUpdateFilters(updatedFormData);
      return updatedFormData;
    });
  };

  return (
    <MainContainer data-tid="product-select">
      <CitySelect
        value={formData.city}
        onChange={(value) => updateFormData('city', value)}
      />
      <CategorySelect
        value={formData.category}
        onChange={(value) => updateFormData('category', value)}
      />
      <DeliverySelect
        value={formData.delivery}
        onChange={(value) => updateFormData('delivery', value)}
      />
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  display: 'flex',
}));

export default ProductSelect;
