import { type TPageConfig } from 'app/AppStateStore';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';
import { lazy } from 'react';

const ProducerPage = lazy(() => import('./ProducerPage'));

const ProducerPageConfig: TPageConfig = {
  settings: {
    layout: themeLayoutConfigs['adminLayout'],
  },
  routes: [
    {
      path: 'producer',
      element: <ProducerPage />,
    },
  ],
};

export default ProducerPageConfig;
