import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

const Apps: FC = () => {
  const { t } = useTranslation('landingFooter');

  return (
    <Grid container direction="row" className="justify-center sm:justify-end">
      <Grid component="span" item>
        <Link
          href="https://www.apple.com/ios/app-store/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="assets/images/ios-en.svg" alt={t('app_store')} />
        </Link>
      </Grid>
      <Grid component="span" item className="ml-12">
        <Link
          href="https://play.google.com/store"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="assets/images/android-en.svg" alt={t('google_play')} />
        </Link>
      </Grid>
    </Grid>
  );
};

export default Apps;
