import { ApolloProvider } from '@apollo/client';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { StyledEngineProvider } from '@mui/material/styles';
import { AppStateProvider } from 'app/AppStateStore';
import i18n from 'i18n';
import { SnackbarProvider } from 'notistack';
import themeLayouts from './layouts/themeLayouts';
import { useMainTheme } from './store/fuse/settingsSlice';

import AuthProvider from './AuthProvider';

import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { buildClient } from '../apolloClientBuilder';

if (process.env.NODE_ENV !== 'production') {
  loadDevMessages();
  loadErrorMessages();
}

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    insertionPoint:
      document.getElementById('emotion-insertion-point') || undefined,
  },
  ltr: {
    key: 'muiltr',
    insertionPoint:
      document.getElementById('emotion-insertion-point') || undefined,
  },
};

const App = () => {
  const client = buildClient();

  return (
    <ApolloProvider client={client}>
      <AppStateProvider>
        <AuthProvider>
          <UIProvider />
        </AuthProvider>
      </AppStateProvider>
    </ApolloProvider>
  );
};

const UIProvider = () => {
  const langDirection = i18n.dir();

  const mainTheme = useMainTheme();

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
        <FuseTheme theme={mainTheme} direction={langDirection}>
          <BrowserRouter>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              classes={{
                containerRoot:
                  'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
              }}
            >
              <FuseLayout layouts={themeLayouts} />
            </SnackbarProvider>
          </BrowserRouter>
        </FuseTheme>
      </CacheProvider>
    </StyledEngineProvider>
  );
};

export default App;
