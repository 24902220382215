import { TDispatchAction, TStateStore } from 'app/AppStateStore';

export const selectChatPanelState = ({ chatPanel }: TStateStore) =>
  chatPanel.state;

export const toggleChatPanel: TDispatchAction<void> = () => (store) => {
  store.chatPanel.state = !store.chatPanel.state;
};

export const openChatPanel: TDispatchAction<void> = () => (store) => {
  store.chatPanel.state = true;
};

export const closeChatPanel: TDispatchAction<void> = () => (store) => {
  store.chatPanel.state = false;
};
