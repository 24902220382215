import { type TPageConfig } from 'app/AppStateStore';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';

const ConsumerAdminPageConfig: TPageConfig = {
  settings: {
    layout: themeLayoutConfigs['adminLayout'],
  },
  routes: [
    {
      path: 'dashboard',
      element: <div>{'Dashboard'}</div>,
    },
  ],
};

export default ConsumerAdminPageConfig;
