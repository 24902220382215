import { useSearchCitiesQuery } from 'generated/graphql-operations';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Error from '../Error';
import Loading from '../Loading';
import CustomSelect from './CustomSelect';

type TCitySelectProps = {
  value: string;
  onChange: (value: string) => void;
};

const CitySelect: FC<TCitySelectProps> = ({ value, onChange }) => {
  const { t } = useTranslation('products');
  const [selectedCity, setSelectedCity] = useState(value);

  const { loading, error, data } = useSearchCitiesQuery();

  useEffect(() => {
    setSelectedCity(value);
  }, [value]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.cities) {
    return <Error message={t('errorReceiveCitySelect')} />;
  }

  const cities =
    data?.cities.edges
      ?.map((edge) => edge?.node)
      .filter((node) => node)
      .map((node) => ({
        value: node?.id || '',
        label: node?.name || '',
      })) || [];

  const handleChange = (city: string) => {
    setSelectedCity(city);
    onChange(city);
  };

  return (
    <div data-tid="city-select">
      <CustomSelect
        label={t('city')}
        value={selectedCity}
        onChange={(event) => handleChange(event.target.value)}
        options={cities}
      />
    </div>
  );
};

export default CitySelect;
