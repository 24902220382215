import Hidden from '@mui/material/Hidden';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Theme, styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'app/AppStateStore';
import {
  navbarCloseMobile,
  selectFuseNavbarMobileOpen,
  selectFuseNavbarOpen,
} from 'app/store/fuse/navbarSlice';
import {
  selectLayoutConfig,
  useNavbarTheme,
} from 'app/store/fuse/settingsSlice';
import NavbarSlideContent from './NavbarSlideContent';

const navbarWidth = 280;

const NavbarSlide = () => {
  const dispatch = useDispatch();
  const config = useSelector(selectLayoutConfig);
  const navbarOpen = useSelector<boolean>(selectFuseNavbarOpen);
  const navbarMobileOpen = useSelector<boolean>(selectFuseNavbarMobileOpen);
  const navbarTheme = useNavbarTheme();

  return (
    <>
      <Hidden lgDown>
        <StyledNavBar
          className="flex-col flex-auto sticky top-0 overflow-hidden h-screen shrink-0 z-20 shadow-5"
          theme={navbarTheme}
          open={navbarOpen}
          position={config.navbar.position}
        >
          <NavbarSlideContent />
        </StyledNavBar>
      </Hidden>

      <Hidden lgUp>
        <StyledNavBarMobile
          classes={{
            paper: 'flex-col flex-auto h-full',
          }}
          anchor={config.navbar.position}
          variant="temporary"
          open={navbarMobileOpen}
          onClose={() => dispatch(navbarCloseMobile())}
          onOpen={() => {}}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavbarSlideContent />
        </StyledNavBarMobile>
      </Hidden>
    </>
  );
};

const StyledNavBar = styled('div')(
  ({
    theme,
    open,
    position,
  }: {
    theme: Theme;
    open: Readonly<boolean>;
    position: 'left' | 'right';
  }) => ({
    minWidth: navbarWidth,
    width: navbarWidth,
    maxWidth: navbarWidth,
    ...(!open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(position === 'left' && {
        marginLeft: `-${navbarWidth}px`,
      }),
      ...(position === 'right' && {
        marginRight: `-${navbarWidth}px`,
      }),
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const StyledNavBarMobile = styled(SwipeableDrawer)(() => ({
  '& .MuiDrawer-paper': {
    minWidth: navbarWidth,
    width: navbarWidth,
    maxWidth: navbarWidth,
  },
}));

export default NavbarSlide;
