export const getNotifications = async () => {
  return [];
};

export const dismissAll = async () => {
  return true;
};

export const dismissItem = async (id: string) => {
  return id;
};

export const addNotification = async (item: object) => {
  return item;
};

export const selectNotificationsById = (id: string) => {};
export const selectNotifications = () => {
  return [];
};

// export const {
//   selectAll: selectNotifications,
//   selectById: selectNotificationsById,
// } = notificationsAdapter.getSelectors(
//   (state: any) => state.notificationPanel.data
// );

// const dataSlice = createSlice({
//   name: 'notificationPanel/data',
//   initialState,
//   reducers: {},
//   extraReducers: {
//     // @ts-ignore
//     [dismissItem.fulfilled]: (state, action) =>
//       notificationsAdapter.removeOne(state, action.payload),
//     // @ts-ignore
//     [dismissAll.fulfilled]: (state, action) =>
//       notificationsAdapter.removeAll(state),
//     // @ts-ignore
//     [getNotifications.fulfilled]: (state, action) =>
//       notificationsAdapter.addMany(state, action.payload),
//     // @ts-ignore
//     [addNotification.fulfilled]: (state, action) =>
//       notificationsAdapter.addOne(state, action.payload),
//   },
// });

// export default dataSlice.reducer;
