import styled from '@mui/material/styles/styled';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Logo = () => {
  const { t } = useTranslation('common');

  return (
    <Root>
      <NavLink to="/" className="flex items-center MuiButtonBase-root">
        <img
          className="logo-icon h-48"
          src="assets/images/logo/logo.png"
          alt={t('app_name')!}
        />
        {/* <span className="text-24 ml-12">{t('app_name')}</span> */}
      </NavLink>
    </Root>
  );
};

const Root = styled('div')(({ theme }) => ({
  '& .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

export default Logo;
