import { TStateStore } from 'app/AppStateStore';

export const getContacts = async (params: any) => {
  return [];
};

export const selectContactById = (id: string) => {};
export const selectContacts = () => {
  return [];
};

export const selectSelectedContactId = ({ chatPanel }: TStateStore) => 1;
