import {
  TNavigationItem,
  useConsumerPageNavigation,
  useLandingPageNavigation,
  useProducerPageNavigation,
} from 'app/configs/navigationConfig';

// TODO: instead of taking navigationType as an argument, we could use the current user role to determine the navigationType
export const useFlatNavigation = (
  navigationType: 'landing' | 'producer' | 'consumer' | 'all'
) => {
  const landingPageNavigation = useLandingPageNavigation();
  const producerNavigation = useProducerPageNavigation();
  const consumerNavigation = useConsumerPageNavigation();

  let navigationItems: TNavigationItem[] = [];

  switch (navigationType) {
    case 'landing':
      navigationItems = landingPageNavigation;
      break;
    case 'producer':
      navigationItems = producerNavigation;
      break;
    case 'consumer':
      navigationItems = consumerNavigation;
      break;
    case 'all':
      navigationItems = [
        ...landingPageNavigation,
        ...producerNavigation, // TODO: only show producer navigation if user is a producer
        ...consumerNavigation, // TODO: only show consumer navigation if user is a consumer
      ];
      break;
  }

  return flattenNavigation(navigationItems);
};

const flattenNavigation = (
  navigationItems: TNavigationItem[],
  flatNavigation: TNavigationItem[] = []
) => {
  for (let i = 0; i < navigationItems.length; i += 1) {
    const navItem = navigationItems[i];

    if (navItem.type === 'item') {
      flatNavigation.push({
        id: navItem.id,
        title: navItem.title,
        type: navItem.type,
        icon: navItem.icon,
        url: navItem.url,
      });
    }

    if (navItem.type === 'collapse' || navItem.type === 'group') {
      if (navItem.children) {
        flattenNavigation(navItem.children, flatNavigation);
      }
    }
  }

  return flatNavigation;
};
