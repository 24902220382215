import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Copyright: FC<{ align?: 'start' | 'center' | 'end' }> = (props) => {
  const { t } = useTranslation('footer');

  let alignClass = '';
  switch (props.align) {
    case 'start':
      alignClass = 'text-left';
      break;
    case 'center':
      alignClass = 'text-center';
      break;
    case 'end':
      alignClass = 'text-right';
      break;
  }

  return (
    <Typography component="p" className={clsx('mt-12 sm:mt-0', alignClass)}>
      {t('copyright', {
        year: new Date().getFullYear(),
        appName: t('common:app_name'),
      })}
    </Typography>
  );
};

export default Copyright;
