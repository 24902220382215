import { memo } from 'react';
import NotificationPanel from 'app/components/shared/notificationPanel/NotificationPanel';
import ChatPanel from 'app/components/shared/chatPanel/ChatPanel';

const RightSideLanding = () => {
  return (
    <>
      <NotificationPanel />
      <ChatPanel />
    </>
  );
};

export default memo(RightSideLanding);
