import FuseSearch from '@fuse/core/FuseSearch';
import { useFlatNavigation } from 'app/core/navigation';
import { FC } from 'react';

const NavigationSearch: FC<{ className?: string; variant?: any }> = (props) => {
  const { variant, className } = props;
  const navigation = useFlatNavigation('all');

  return (
    <FuseSearch
      className={className}
      variant={variant}
      // @ts-ignore
      navigation={navigation}
    />
  );
};

export default NavigationSearch;
