import { TDispatchAction, TStateStore } from 'app/AppStateStore';

export const selectNotificationPanelState = ({
  notificationPanel,
}: TStateStore) => notificationPanel.state;

export const toggleNotificationPanel: TDispatchAction<void> = () => (store) => {
  store.notificationPanel.state = !store.notificationPanel.state;
};

export const openNotificationPanel: TDispatchAction<void> = () => (store) => {
  store.notificationPanel.state = true;
};

export const closeNotificationPanel: TDispatchAction<void> = () => (store) => {
  store.notificationPanel.state = false;
};
