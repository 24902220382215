type TPaletteText = {
  primary: string;
  secondary: string;
  disabled: string;
};

export type TThemeOption = {
  themeType: keyof TThemeTypes;
  section: {
    main: TPaletteConfig;
    navbar: TPaletteConfig;
    toolbar: TPaletteConfig;
    footer: TPaletteConfig;
  };
};

export type TThemeTypes = {
  defaultLight: TPaletteConfig;
  defaultDark: TPaletteConfig;

  darkBlueSilverLight: TPaletteConfig;
  darkBlueSilverDark: TPaletteConfig;

  slateCrimsonLight: TPaletteConfig;
  slateCrimsonDark: TPaletteConfig;

  emeraldGoldLight: TPaletteConfig;
  emeraldGoldDark: TPaletteConfig;

  indigoCoralLight: TPaletteConfig;
  indigoCoralDark: TPaletteConfig;

  charcoalTealLight: TPaletteConfig;
  charcoalTealDark: TPaletteConfig;

  skyBlueOrangeLight: TPaletteConfig;
  skyBlueOrangeDark: TPaletteConfig;

  softGreenMaroonLight: TPaletteConfig;
  softGreenMaroonDark: TPaletteConfig;

  coolGreyPinkLight: TPaletteConfig;
  coolGreyPinkDark: TPaletteConfig;
};

export type TPaletteConfig = {
  palette: {
    mode: 'light' | 'dark';
    divider: string;
    text: TPaletteText;
    common?: {
      black: string;
      white: string;
    };
    primary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
    secondary: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
    background: {
      paper: string;
      default: string;
    };
    error?: {
      light: string;
      main: string;
      dark: string;
      contrastText?: string;
    };
  };
  status?: {
    danger: string;
  };
};

/**
 * The lightPaletteText object defines the text color palette for the light theme.
 */
export const lightPaletteText = {
  primary: 'rgb(17, 24, 39)',
  secondary: 'rgb(107, 114, 128)',
  disabled: 'rgb(149, 156, 169)',
};

/**
 * The darkPaletteText object defines the text color palette for the dark theme.
 */
export const darkPaletteText = {
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(148, 163, 184)',
  disabled: 'rgb(156, 163, 175)',
};

/**
 * The themesConfig object is a configuration object for the color themes of the Fuse application.
 */
export const themesConfig: TThemeTypes = {
  defaultLight: {
    palette: {
      mode: 'light',
      divider: '#e2e8f0',
      text: lightPaletteText,
      common: {
        black: 'rgb(17, 24, 39)',
        white: 'rgb(255, 255, 255)',
      },
      primary: {
        light: '#64748b',
        main: '#1e293b',
        dark: '#0f172a',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: '#818cf8',
        main: '#4f46e5',
        dark: '#3730a3',
        contrastText: darkPaletteText.primary,
      },
      background: {
        paper: '#FFFFFF',
        default: '#f1f5f9',
      },
      error: {
        light: '#ffcdd2',
        main: '#f44336',
        dark: '#b71c1c',
        contrastText: darkPaletteText.primary,
      },
    },
  },
  defaultDark: {
    palette: {
      mode: 'dark',
      divider: 'rgba(241,245,249,.12)',
      text: darkPaletteText,
      common: {
        black: 'rgb(17, 24, 39)',
        white: 'rgb(255, 255, 255)',
      },
      primary: {
        light: '#64748b',
        main: '#334155',
        dark: '#0f172a',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        light: '#818cf8',
        main: '#4f46e5',
        dark: '#3730a3',
        contrastText: darkPaletteText.primary,
      },
      background: {
        paper: '#1e293b',
        default: '#111827',
      },
      error: {
        light: '#ffcdd2',
        main: '#f44336',
        dark: '#b71c1c',
      },
    },
  },
  darkBlueSilverLight: {
    palette: {
      mode: 'light',
      primary: {
        main: '#0D47A1',
        light: '#5472D3',
        dark: '#002171',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#B0BEC5',
        light: '#E2F1F8',
        dark: '#808E95',
        contrastText: lightPaletteText.primary,
      },
      background: {
        paper: '#FFFFFF',
        default: '#f1f5f9',
      },
      text: lightPaletteText,
      divider: '#d8d9da',
    },
  },
  darkBlueSilverDark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#0D47A1',
        light: '#5472D3',
        dark: '#002171',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#B0BEC5',
        light: '#E2F1F8',
        dark: '#808E95',
        contrastText: lightPaletteText.primary,
      },
      background: {
        default: '#263238',
        paper: '#2d3940',
      },
      text: darkPaletteText,
      divider: '#42474d',
    },
  },
  slateCrimsonLight: {
    palette: {
      mode: 'light',
      primary: {
        main: '#37474F',
        light: '#62727B',
        dark: '#102027',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#D32F2F',
        light: '#FF6659',
        dark: '#9A0007',
        contrastText: darkPaletteText.primary,
      },
      background: {
        default: '#e6e6e6',
        paper: '#f2f2f2',
      },
      text: lightPaletteText,
      divider: '#d9d9d9',
    },
  },
  slateCrimsonDark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#37474F',
        light: '#62727B',
        dark: '#102027',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#D32F2F',
        light: '#FF6659',
        dark: '#9A0007',
        contrastText: darkPaletteText.primary,
      },
      background: {
        default: '#212121',
        paper: '#2e2e2e',
      },
      text: darkPaletteText,
      divider: '#3a3d40',
    },
  },
  emeraldGoldLight: {
    palette: {
      mode: 'light',
      primary: {
        main: '#00695C',
        light: '#439889',
        dark: '#003D33',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#FFD740',
        light: '#FFFF74',
        dark: '#C8A600',
        contrastText: lightPaletteText.primary,
      },
      background: {
        default: '#dcf2f2',
        paper: '#f2fdfa',
      },
      text: lightPaletteText,
      divider: '#b3c4c3',
    },
  },
  emeraldGoldDark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#00695C',
        light: '#439889',
        dark: '#003D33',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#FFD740',
        light: '#FFFF74',
        dark: '#C8A600',
        contrastText: lightPaletteText.primary,
      },
      background: {
        default: '#004D40',
        paper: '#00544a',
      },
      text: darkPaletteText,
      divider: '#2d6360',
    },
  },
  indigoCoralLight: {
    palette: {
      mode: 'light',
      primary: {
        main: '#283593',
        light: '#5F5FC4',
        dark: '#001064',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#FF7043',
        light: '#FFA270',
        dark: '#C63F17',
        contrastText: lightPaletteText.primary,
      },
      background: {
        default: '#eaebfb',
        paper: '#f6f7fd',
      },
      text: lightPaletteText,
      divider: '#dcdcf2',
    },
  },
  indigoCoralDark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#283593',
        light: '#5F5FC4',
        dark: '#001064',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#FF7043',
        light: '#FFA270',
        dark: '#C63F17',
        contrastText: lightPaletteText.primary,
      },
      background: {
        default: '#1A237E',
        paper: '#283593',
      },
      text: darkPaletteText,
      divider: '#4d557e',
    },
  },
  charcoalTealLight: {
    palette: {
      mode: 'light',
      primary: {
        main: '#094a43',
        light: '#28635a',
        dark: '#004a41',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#009688',
        light: '#52C7B8',
        dark: '#00675B',
        contrastText: darkPaletteText.primary,
      },
      background: {
        default: '#edf6fa',
        paper: '#f7fcfc',
      },
      text: lightPaletteText,
      divider: '#cee5f0',
    },
  },
  charcoalTealDark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#455A64',
        light: '#718792',
        dark: '#1C313A',
        contrastText: darkPaletteText.primary,
      },
      secondary: {
        main: '#009688',
        light: '#52C7B8',
        dark: '#00675B',
        contrastText: darkPaletteText.primary,
      },
      background: {
        default: '#000000',
        paper: '#102027',
      },
      text: darkPaletteText,
      divider: '#2d383d',
    },
  },
  skyBlueOrangeLight: {
    palette: {
      mode: 'light',
      primary: {
        main: '#64B5F6',
        light: '#9BE7FF',
        dark: '#2286C3',
        contrastText: lightPaletteText.primary,
      },
      secondary: {
        main: '#faa528',
        light: '#f6ad3f',
        dark: '#cb8721',
        contrastText: lightPaletteText.primary,
      },
      background: {
        default: '#F5F5F5',
        paper: '#FFFFFF',
      },
      text: lightPaletteText,
      divider: '#e9e6e0',
    },
  },
  skyBlueOrangeDark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#64B5F6',
        light: '#9BE7FF',
        dark: '#2286C3',
        contrastText: lightPaletteText.primary,
      },
      secondary: {
        main: '#faa528',
        light: '#f6ad3f',
        dark: '#cb8721',
        contrastText: lightPaletteText.primary,
      },
      background: {
        default: '#1a1a1a',
        paper: '#333333',
      },
      text: darkPaletteText,
      divider: '#544949',
    },
  },
  softGreenMaroonLight: {
    palette: {
      mode: 'light',
      primary: {
        main: '#81C784',
        light: '#B2F2B6',
        dark: '#519657',
        contrastText: lightPaletteText.primary,
      },
      secondary: {
        main: '#D81B60',
        light: '#FF5C8D',
        dark: '#A00037',
        contrastText: darkPaletteText.primary,
      },
      background: {
        default: '#f5f5f5',
        paper: '#fafcfa',
      },
      text: lightPaletteText,
      divider: '#dadeda',
    },
  },
  softGreenMaroonDark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#81C784',
        light: '#B2F2B6',
        dark: '#519657',
        contrastText: lightPaletteText.primary,
      },
      secondary: {
        main: '#D81B60',
        light: '#FF5C8D',
        dark: '#A00037',
        contrastText: darkPaletteText.primary,
      },
      background: {
        default: '#1a1a1a',
        paper: '#323332',
      },
      text: darkPaletteText,
      divider: '#505250',
    },
  },
  coolGreyPinkLight: {
    palette: {
      mode: 'light',
      primary: {
        main: '#dde6eb',
        light: '#FFFFFF',
        dark: '#9EA7AA',
        contrastText: lightPaletteText.primary,
      },
      secondary: {
        main: '#F06292',
        light: '#FF94C2',
        dark: '#BA2D65',
        contrastText: darkPaletteText.primary,
      },
      background: {
        default: '#F5F5F5',
        paper: '#FFFFFF',
      },
      text: lightPaletteText,
      divider: '#e1e1e1',
    },
  },
  coolGreyPinkDark: {
    palette: {
      mode: 'dark',
      primary: {
        main: '#dde6eb',
        light: '#FFFFFF',
        dark: '#9EA7AA',
        contrastText: lightPaletteText.primary,
      },
      secondary: {
        main: '#F06292',
        light: '#FF94C2',
        dark: '#BA2D65',
        contrastText: darkPaletteText.primary,
      },
      background: {
        default: '#1a1a1a',
        paper: '#292929',
      },
      text: darkPaletteText,
      divider: '#424242',
    },
  },
};

export const defaultThemeOptions = {
  typography: {
    fontFamily: [
      'Inter var',
      'Roboto',
      '"Helvetica"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiDateTimePicker: {
      defaultProps: {
        PopperProps: { className: 'z-9999' },
      },
    },
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'text',
        color: 'inherit',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          // lineHeight: 1,
        },
        sizeMedium: {
          borderRadius: 20,
          height: 40,
          minHeight: 40,
          maxHeight: 40,
        },
        sizeSmall: {
          borderRadius: '15px',
        },
        sizeLarge: {
          borderRadius: '28px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        contained: {
          borderRadius: 18,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: 16,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSelect: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 40,
          lineHeight: 1,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:before, &:after': {
            display: 'none',
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { color: 'text.secondary' },
          style: {
            color: 'text.secondary',
          },
        },
      ],
    },
  },
};

export const mustHaveThemeOptions = {
  typography: {
    htmlFontSize: 10,
    fontSize: 14,
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
  },
};

const themeOptions: TThemeOption[] = [
  {
    themeType: 'defaultLight',
    section: {
      main: themesConfig.defaultLight,
      navbar: themesConfig.defaultDark,
      toolbar: themesConfig.defaultLight,
      footer: themesConfig.defaultDark,
    },
  },
  {
    themeType: 'defaultDark',
    section: {
      main: themesConfig.defaultDark,
      navbar: themesConfig.defaultDark,
      toolbar: themesConfig.defaultDark,
      footer: themesConfig.defaultDark,
    },
  },
  {
    themeType: 'charcoalTealLight',
    section: {
      main: themesConfig.charcoalTealLight,
      navbar: themesConfig.charcoalTealDark,
      toolbar: themesConfig.charcoalTealLight,
      footer: themesConfig.charcoalTealDark,
    },
  },
  {
    themeType: 'charcoalTealDark',
    section: {
      main: themesConfig.charcoalTealDark,
      navbar: themesConfig.charcoalTealDark,
      toolbar: themesConfig.charcoalTealDark,
      footer: themesConfig.charcoalTealDark,
    },
  },
  {
    themeType: 'darkBlueSilverLight',
    section: {
      main: themesConfig.darkBlueSilverLight,
      navbar: themesConfig.darkBlueSilverDark,
      toolbar: themesConfig.darkBlueSilverLight,
      footer: themesConfig.darkBlueSilverDark,
    },
  },
  {
    themeType: 'darkBlueSilverDark',
    section: {
      main: themesConfig.darkBlueSilverDark,
      navbar: themesConfig.darkBlueSilverDark,
      toolbar: themesConfig.darkBlueSilverDark,
      footer: themesConfig.darkBlueSilverDark,
    },
  },
  {
    themeType: 'slateCrimsonLight',
    section: {
      main: themesConfig.slateCrimsonLight,
      navbar: themesConfig.slateCrimsonDark,
      toolbar: themesConfig.slateCrimsonLight,
      footer: themesConfig.slateCrimsonDark,
    },
  },
  {
    themeType: 'slateCrimsonDark',
    section: {
      main: themesConfig.slateCrimsonDark,
      navbar: themesConfig.slateCrimsonDark,
      toolbar: themesConfig.slateCrimsonDark,
      footer: themesConfig.slateCrimsonDark,
    },
  },
  {
    themeType: 'emeraldGoldLight',
    section: {
      main: themesConfig.emeraldGoldLight,
      navbar: themesConfig.emeraldGoldDark,
      toolbar: themesConfig.emeraldGoldLight,
      footer: themesConfig.emeraldGoldDark,
    },
  },
  {
    themeType: 'emeraldGoldDark',
    section: {
      main: themesConfig.emeraldGoldDark,
      navbar: themesConfig.emeraldGoldDark,
      toolbar: themesConfig.emeraldGoldDark,
      footer: themesConfig.emeraldGoldDark,
    },
  },
  {
    themeType: 'indigoCoralLight',
    section: {
      main: themesConfig.indigoCoralLight,
      navbar: themesConfig.indigoCoralDark,
      toolbar: themesConfig.indigoCoralLight,
      footer: themesConfig.indigoCoralDark,
    },
  },
  {
    themeType: 'indigoCoralDark',
    section: {
      main: themesConfig.indigoCoralDark,
      navbar: themesConfig.indigoCoralDark,
      toolbar: themesConfig.indigoCoralDark,
      footer: themesConfig.indigoCoralDark,
    },
  },
  {
    themeType: 'skyBlueOrangeLight',
    section: {
      main: themesConfig.skyBlueOrangeLight,
      navbar: themesConfig.skyBlueOrangeDark,
      toolbar: themesConfig.skyBlueOrangeLight,
      footer: themesConfig.skyBlueOrangeDark,
    },
  },
  {
    themeType: 'skyBlueOrangeDark',
    section: {
      main: themesConfig.skyBlueOrangeDark,
      navbar: themesConfig.skyBlueOrangeDark,
      toolbar: themesConfig.skyBlueOrangeDark,
      footer: themesConfig.skyBlueOrangeDark,
    },
  },
  {
    themeType: 'softGreenMaroonLight',
    section: {
      main: themesConfig.softGreenMaroonLight,
      navbar: themesConfig.softGreenMaroonDark,
      toolbar: themesConfig.softGreenMaroonLight,
      footer: themesConfig.softGreenMaroonDark,
    },
  },
  {
    themeType: 'softGreenMaroonDark',
    section: {
      main: themesConfig.softGreenMaroonDark,
      navbar: themesConfig.softGreenMaroonDark,
      toolbar: themesConfig.softGreenMaroonDark,
      footer: themesConfig.softGreenMaroonDark,
    },
  },
  {
    themeType: 'coolGreyPinkLight',
    section: {
      main: themesConfig.coolGreyPinkLight,
      navbar: themesConfig.coolGreyPinkDark,
      toolbar: themesConfig.coolGreyPinkLight,
      footer: themesConfig.coolGreyPinkDark,
    },
  },
  {
    themeType: 'coolGreyPinkDark',
    section: {
      main: themesConfig.coolGreyPinkDark,
      navbar: themesConfig.coolGreyPinkDark,
      toolbar: themesConfig.coolGreyPinkDark,
      footer: themesConfig.coolGreyPinkDark,
    },
  },
];

export default themeOptions;
