import { type TPageConfig } from 'app/AppStateStore';
import SearchPage from 'app/components/shared/search/SearchPage';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';
import MainPage from './MainPage';

import { lazy } from 'react';

const ProductDetails = lazy(
  () => import('app/components/shared/product/ProductDetails')
);

const MainPageConfig: TPageConfig = {
  settings: {
    layout: themeLayoutConfigs['landingLayout'],
  },
  routes: [
    {
      path: '/',
      element: <MainPage />,
    },
    {
      path: '/product/:id',
      element: <ProductDetails />,
    },
    {
      path: '/search',
      element: <SearchPage />,
    },
  ],
};

export default MainPageConfig;
