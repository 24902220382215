import Button from '@mui/material/Button';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductSelect from './ProductDetailedFilter';
import ProductSearchInput from './ProductSearchInput';

export type TFormData = {
  city: string;
  category: string;
  delivery: string;
};

const ProductFilter: FC = () => {
  const { t } = useTranslation('products');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const initialFormData: TFormData = {
    city: queryParams.get('city') || '',
    category: queryParams.get('category') || '',
    delivery: queryParams.get('delivery') || '',
  };

  const [formData, setFormData] = useState<TFormData>(initialFormData);
  const [searchInput, setSearchInput] = useState<string>(
    queryParams.get('search') || ''
  );

  const handleSearchRequest = () => {
    const { city, category, delivery } = formData;
    const queryParams = new URLSearchParams();

    if (searchInput) {
      queryParams.set('search', searchInput);
    }
    if (city) {
      queryParams.set('city', city);
    }
    if (category) {
      queryParams.set('category', category);
    }
    if (delivery) {
      queryParams.set('delivery', delivery);
    }

    navigate(`/search?${queryParams.toString()}`);
  };

  return (
    <div data-tid="product-filter">
      <ProductSearchInput
        value={searchInput}
        onSearchQueryChange={(input) => setSearchInput(input)}
      />
      <div className="flex">
        <ProductSelect
          initialFilters={initialFormData}
          onUpdateFilters={(data) =>
            setFormData((prev) => ({ ...prev, ...data }))
          }
        />
        <Button
          className="whitespace-nowrap mx-4"
          variant="contained"
          color="secondary"
          onClick={handleSearchRequest}
          sx={{ m: 1, width: 300 }}
        >
          {t('show')}
        </Button>
      </div>
    </div>
  );
};

export default ProductFilter;
