import {
  TDispatchAction,
  TMessageOptions,
  TStateStore,
} from 'app/AppStateStore';

export const selectFuseMessageState = ({ message }: TStateStore) =>
  message.state;

export const selectFuseMessageOptions = ({ message }: TStateStore) =>
  message.options;

export const showMessage: TDispatchAction<TMessageOptions> =
  (messageOptions) => (store) => {
    store.message.options.message = messageOptions.message;
  };

export const hideMessage: TDispatchAction<void> = () => (store) => {
  store.message.state = false;
};
