import { type TPageConfig } from 'app/AppStateStore';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';
import { lazy } from 'react';

const ConsumerPage = lazy(() => import('./ConsumerPage'));

const ConsumerPageConfig: TPageConfig = {
  settings: {
    layout: themeLayoutConfigs['landingLayout'],
  },
  routes: [
    {
      path: 'consumer',
      element: <ConsumerPage />,
    },
  ],
};

export default ConsumerPageConfig;
