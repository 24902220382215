import styled from '@mui/material/styles/styled';
import { useProductsByTypeQuery } from 'generated/graphql-operations';
import i18n from 'i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Error from '../Error';
import Loading from '../Loading';
import ProductCard from './ProductCard';

interface ProductListProps {
  productType: 'new' | 'recommended' | 'promoted';
}

const ProductList: FC<ProductListProps> = ({ productType }) => {
  const { loading, error, data } = useProductsByTypeQuery({
    variables: { productType },
  });

  const { t } = useTranslation('products');

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.products?.edges) {
    return <Error message={t('errorReceiveProductListData')} />;
  }
  const productsMap = data.products.edges;

  return (
    <MainContainer data-tid={productType}>
      <HeadContainer>
        <h3>{i18n.t(`products:${productType}`)}</h3>
        <Link to={`/products/${productType}`}>{t('allProducts')}</Link>
      </HeadContainer>
      <DivContainer>
        {productsMap.map((product, index) => {
          if (!product?.node) {
            return null;
          }

          const { id, title, imageUrl, rating, category, owner } = product.node;

          return (
            <ProductContainer key={index}>
              <ProductCard
                key={id}
                title={title}
                imageUrl={imageUrl}
                rating={rating?.averageRating || 0}
                category={category.name}
                owner={owner.displayName}
                id={id}
                description={''}
                createdAt={new Date()}
                city={{
                  name: '',
                  id: '',
                }}
                delivery={{
                  name: '',
                  id: '',
                }}
              />
            </ProductContainer>
          );
        })}
      </DivContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  margin: '2.4rem 0 2.4rem ',
}));
const HeadContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
const DivContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}));
const ProductContainer = styled('div')(() => ({
  padding: '1.9rem',
  alignItems: 'center',
  gap: '10px',
}));

export default ProductList;
