import { type TPageConfig } from 'app/AppStateStore';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';
import { lazy } from 'react';

const Dashboard = lazy(() => import('app/components/producer/Dashboard'));
const Orders = lazy(() => import('app/components/producer/orders/Orders'));
const Products = lazy(
  () => import('app/components/producer/products/Products')
);
const Recipes = lazy(() => import('app/components/producer/recipes/Recipes'));
const Sales = lazy(() => import('app/components/producer/sales/Sales'));
const Profile = lazy(() => import('app/components/shared/Profile'));
const Settings = lazy(() => import('app/components/shared/Settings'));

const ProducerAdminPageConfig: TPageConfig = {
  settings: {
    layout: themeLayoutConfigs['adminLayout'],
  },
  routes: [
    {
      path: 'dashboard',
      element: <Dashboard />,
    },
    {
      path: 'orders/:orderType/:orderId/:orderDetailType',
      element: <Orders />,
    },
    {
      path: 'orders/:orderType/:orderId',
      element: <Orders />,
    },
    {
      path: 'orders/:orderType',
      element: <Orders />,
    },
    {
      path: 'my-sales/:saleId/:saleDetailType',
      element: <Sales />,
    },
    {
      path: 'my-sales/:saleId',
      element: <Sales />,
    },
    {
      path: 'my-sales',
      element: <Sales />,
    },
    {
      path: 'my-products/:productId/:productDetailType',
      element: <Products />,
    },
    {
      path: 'my-products/:productId',
      element: <Products />,
    },
    {
      path: 'my-products',
      element: <Products />,
    },
    {
      path: 'my-recipes/:recipeId/:recipeDetailType',
      element: <Recipes />,
    },
    {
      path: 'my-recipes/:recipeId',
      element: <Recipes />,
    },
    {
      path: 'my-recipes',
      element: <Recipes />,
    },
    {
      path: 'profile/:tab',
      element: <Profile />,
    },
    {
      path: 'profile',
      element: <Profile />,
    },
    {
      path: 'settings/:tab',
      element: <Settings />,
    },
    {
      path: 'settings',
      element: <Settings />,
    },
  ],
};

export default ProducerAdminPageConfig;
