import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

type TProductSearchInput = {
  value: string;
  onSearchQueryChange: (searchInput: string) => void;
};

const ProductSearchInput: FC<TProductSearchInput> = ({
  value,
  onSearchQueryChange,
}) => {
  const { t } = useTranslation('products');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchInput = event.target.value;
    onSearchQueryChange(newSearchInput);
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '99%', maxWidth: 'xl' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="product-filter-text"
          placeholder={t('selectProduct')}
          name="product-filter-text"
          value={value}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </div>
    </Box>
  );
};

export default ProductSearchInput;
