import { useSearchDeliveryQuery } from 'generated/graphql-operations';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Error from '../Error';
import Loading from '../Loading';
import CustomSelect from './CustomSelect';

type TDeliverySelectProps = {
  value: string;
  onChange: (value: string) => void;
};

const DeliverySelect: FC<TDeliverySelectProps> = ({ value, onChange }) => {
  const { t } = useTranslation('products');
  const [selectedDelivery, setSelectedDelivery] = useState(value);

  const { loading, error, data } = useSearchDeliveryQuery();

  useEffect(() => {
    setSelectedDelivery(value);
  }, [value]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.delivery) {
    return <Error message={t('errorReceiveDeliverySelect')} />;
  }

  const deliveries =
    data?.delivery.edges
      ?.map((edge) => edge?.node)
      .filter((node) => node)
      .map((node) => ({
        value: node?.id || '',
        label: node?.name || '',
      })) || [];

  const handleChange = (delivery: string) => {
    setSelectedDelivery(delivery);
    onChange(delivery);
  };

  return (
    <div data-tid="delivery-select">
      <CustomSelect
        label={t('delivery')}
        value={selectedDelivery}
        onChange={(event) => handleChange(event.target.value)}
        options={deliveries}
      />
    </div>
  );
};

export default DeliverySelect;
