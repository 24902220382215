import { FC } from 'react';

// TODO: style and translate error component
const Error: FC<{ message?: string; title?: string }> = (props) => {
  const { message, title } = props;

  return (
    <div>
      <h2>{title || 'Error'}</h2>
      <p>{message || 'Unknown error'}</p>
    </div>
  );
};

export default Error;
