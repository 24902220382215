import FusePageSimple from '@fuse/core/FusePageSimple';
import styled from '@mui/material/styles/styled';
import ProductList from 'app/components/shared/product/ProductList';
import ProductFilter from 'app/components/shared/search/ProductFilter';
import { useTranslation } from 'react-i18next';

const MainPage = () => {
  const { t } = useTranslation('common');

  return (
    <Root
      header={
        <>
          <div className="p-24">
            <h2>{t('app_slogan')}</h2>
          </div>
        </>
      }
      content={
        <div className="p-24">
          <ProductFilter />
          <ProductList productType="new" />
          <ProductList productType="recommended" />
          <ProductList productType="promoted" />
        </div>
      }
      scroll="content"
    />
  );
};

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {
    display: 'flex',
  },
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

export default MainPage;
