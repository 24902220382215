import FuseNavigation from '@fuse/core/FuseNavigation';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Theme } from '@mui/system';
import { AgnosticRouteMatch } from '@remix-run/router';
import { useDispatch } from 'app/AppStateStore';
import {
  TNavigationItem,
  useLandingPageNavigation,
  useProducerPageNavigation,
} from 'app/configs/navigationConfig';
import { ProducerPageRoutes } from 'app/configs/routesConfig';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import clsx from 'clsx';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { RouteObject, matchRoutes, useLocation } from 'react-router-dom';

const Navigation: FC<{
  className?: string;
  active?: boolean;
  dense?: boolean;
  layout?: 'vertical' | 'horizontal';
}> = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const isMobile = useThemeMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  return useMemo(() => {
    function handleItemClick() {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <FuseNavigation
        className={clsx('navigation', props.className)}
        navigation={navigation}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
      />
    );
  }, [
    dispatch,
    isMobile,
    navigation,
    props.active,
    props.className,
    props.dense,
    props.layout,
  ]);
};

export default memo(Navigation);

const useNavigation = () => {
  const [navigation, setNavigation] = useState<TNavigationItem[]>([]);
  const location = useLocation();
  const producerPageNavigation = useProducerPageNavigation();
  const landingPageNavigation = useLandingPageNavigation();

  useEffect(() => {
    const { pathname } = location;
    let result: TNavigationItem[] = landingPageNavigation;

    let matchedRoutes: AgnosticRouteMatch<string, RouteObject>[] | null;

    // TODO: return navigation config based on user type
    // if (user.type === 'producer') {
    //   matchedRoutes = matchRoutes(ProducerPageRoutes, pathname);

    //   if (matchedRoutes) {
    //     result = producerNavigationConfig;
    //   }
    // } else (user.type === 'consumer') {
    //   matchedRoutes = matchRoutes(ConsumerPageRoutes, pathname);
    //   if (matchedRoutes) {
    //     result = consumerNavigationConfig;
    //   }
    // }

    matchedRoutes = matchRoutes(ProducerPageRoutes, pathname);

    if (matchedRoutes) {
      result = producerPageNavigation;
    }

    setNavigation(result);
  }, [location, landingPageNavigation, producerPageNavigation]);

  return navigation;
};
