import { useState } from 'react';

import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'app/AppStateStore';
import {
  changeLanguage,
  selectSupportedLanguages,
} from 'app/store/fuse/settingsSlice';
import { TLanguage, defaultLanguage, default as i18n } from 'i18n';

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const supportedLanguages = useSelector(selectSupportedLanguages);
  const [menu, setMenu] = useState<HTMLElement | null>(null);
  const [currentLanguage, setCurrentLanguage] = useState<TLanguage>(
    getCurrentLocale(supportedLanguages)
  );

  const langMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };

  const handleLanguageChange = (lng: TLanguage) => {
    dispatch(changeLanguage(lng.id));
    setCurrentLanguage(lng);

    langMenuClose();
  };

  return (
    <>
      <Button className="h-40 w-64" onClick={langMenuClick}>
        <img
          className="mx-4 min-w-20"
          src={`assets/images/flags/${currentLanguage.flag.toUpperCase()}.svg`}
          alt={currentLanguage.title}
        />

        <Typography
          className="mx-4 font-semibold uppercase"
          color="text.primary"
        >
          {currentLanguage.id}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {supportedLanguages.map((lng) => (
          <MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
            <ListItemIcon className="min-w-40">
              <img
                className="min-w-20"
                src={`assets/images/flags/${lng.flag.toUpperCase()}.svg`}
                alt={lng.title}
              />
            </ListItemIcon>
            <ListItemText primary={lng.title} />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

const getCurrentLocale = (supportedLanguages: Readonly<TLanguage[]>) => {
  let result = supportedLanguages.find(
    (loc) => loc.id === i18n.language.substring(0, 2)
  );

  if (!result) {
    result = supportedLanguages.find((loc) => loc.id === defaultLanguage)!;
    i18n.changeLanguage(result.id);
  }

  return result;
};

export default LanguageSwitcher;
