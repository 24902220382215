import { TLayoutConfig } from 'app/AppStateStore';

const config: TLayoutConfig = {
  mode: 'container',
  containerWidth: 1280,
  navbar: {
    display: true,
    style: 'slide',
    folded: false,
    position: 'left',
  },
  toolbar: {
    display: true,
    style: 'fixed',
    position: 'below',
  },
  footer: {
    display: true,
    style: 'static',
  },
  leftSidePanel: {
    display: true,
  },
  rightSidePanel: {
    display: false,
  },
};

export default config;
