import { type TPageConfig } from 'app/AppStateStore';
import { lazy } from 'react';

const ForgotPasswordPage = lazy(() => import('./ForgotPasswordPage'));

const ForgotPasswordConfig: TPageConfig = {
  settings: {
    layout: {
      navbar: {
        display: false,
      },
      toolbar: {
        display: false,
      },
      footer: {
        display: false,
      },
      leftSidePanel: {
        display: false,
      },
      rightSidePanel: {
        display: false,
      },
    },
  },
  routes: [
    {
      path: 'auth/forgot-password/:role',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'auth/forgot-password',
      element: <ForgotPasswordPage />,
    },
  ],
};

export default ForgotPasswordConfig;
