import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './locales/en/strings.json';
import uk from './locales/uk/strings.json';

export const changeLanguage = (lng: TSupportedLanguages) => {
  i18next.changeLanguage(lng);
  localStorage.setItem('i18nextLng', lng);
};

export const defaultLanguage: TSupportedLanguages = 'uk';

export const supportedLanguages: ReadonlyArray<TLanguage> = [
  { id: 'en', title: 'English', flag: 'us' },
  { id: 'uk', title: 'Українська', flag: 'ua' },
];

export type TSupportedLanguages = 'en' | 'uk';

export type TLanguage = {
  id: TSupportedLanguages;
  title: string;
  flag: string;
};

type TResource = {
  [key in TSupportedLanguages]: { [key: string]: { [key: string]: string } };
};

const availableLanguages: TResource = {
  en: en,
  uk: uk,
};

const generateResources = () => {
  const namespaces: string[] = [];
  const resources: TResource = {} as TResource;

  for (const lng in availableLanguages) {
    resources[lng as TSupportedLanguages] =
      availableLanguages[lng as TSupportedLanguages];
  }

  for (const ns in en) {
    namespaces.push(ns);
  }

  return {
    namespaces,
    resources,
  };
};

const { namespaces, resources } = generateResources();

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: defaultLanguage,
    resources: resources,
    ns: namespaces,
    defaultNS: 'common',
    load: 'languageOnly',
    keySeparator: '.',
    supportedLngs: supportedLanguages.map((lang) => lang.id),
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18next;
