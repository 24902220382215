import history from '@history';
import { useLayoutEffect, useState } from 'react';
// TODO: Change Hash Router for regular router when app is done.
import { BrowserRouterProps, HashRouter as Router } from 'react-router-dom';

/**
 * The BrowserRouter component provides an interface for users to interact with the application's history.
 * It is responsible for managing the routing through the use of the `react-router-dom` library and the browser's `history` object.
 * It also integrates with `useLayoutEffect` to listen for changes to the `location` state from the browser's `history` object.
 */
function BrowserRouter(props: BrowserRouterProps) {
  const { basename, children } = props;

  const [setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [setState]);

  return (
    <Router
      basename={basename}
      // TODO: Change Hash Router for regular router when app is done.
      // location={state.location}
      // navigationType={state.action}
      // navigator={history}
    >
      {children}
    </Router>
  );
}

export default BrowserRouter;
