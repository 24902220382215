import { TDispatchAction, TStateStore, TUser } from 'app/AppStateStore';
import merge from 'lodash/fp/merge';

export const setUser: TDispatchAction<TUser> =
  (user: TUser) => (store: TStateStore) => {
    store.user = user;
  };

export const signOut: TDispatchAction<void> = () => (store: TStateStore) => {
  store.user = undefined;
};

export const updateUserSettings = async (settings: any) => {
  const { user } = { user: {} };
  const newUser = merge(user, { data: { settings } });

  // dispatch(updateUserData(newUser));

  return newUser;
};

export const updateUserShortcuts = async (shortcuts: any) => {
  const { user } = { user: { data: {} } };
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts,
    },
  };

  // @ts-ignore
  dispatch(updateUserData(newUser));

  return newUser;
};

export const updateUserData =
  (user: TUser) => async (dispatch: any, getState: any) => {};

export const selectUser = (store: TStateStore) => store.user;

export const selectUserShortcuts = ({ user }: TStateStore) =>
  user?.shortcuts || [];
