import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Navigation: FC = () => {
  const { t } = useTranslation('footer');

  return (
    <Box component="nav">
      <List dense={true} className="p-0">
        <ListItem className="px-0">
          <NavLink to="/contacts">{t('contacts')}</NavLink>
        </ListItem>
        <ListItem className="px-0">
          <NavLink to="/privacy">{t('privacy')}</NavLink>
        </ListItem>
        <ListItem className="px-0">
          <NavLink to="/terms">{t('terms_of_use')}</NavLink>
        </ListItem>
      </List>
    </Box>
  );
};

export default Navigation;
