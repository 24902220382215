import { type TPageConfig } from 'app/AppStateStore';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Error404Page = lazy(() => import('app/pages/404/Error404Page'));

const Error404PageConfig: TPageConfig = {
  settings: {
    // TODO: fix this so that it uses the landing layout if the user is not logged in
    layout: themeLayoutConfigs['adminLayout'],
  },
  routes: [
    {
      path: '404',
      element: <Error404Page />,
    },
    {
      path: '*',
      element: <Navigate to="404" />,
    },
  ],
};

export default Error404PageConfig;
