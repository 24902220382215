import { type TPageConfig } from 'app/AppStateStore';
import { lazy } from 'react';

const ResetPasswordPage = lazy(() => import('./ResetPasswordPage'));

const ResetPasswordConfig: TPageConfig = {
  settings: {
    layout: {
      navbar: {
        display: false,
      },
      toolbar: {
        display: false,
      },
      footer: {
        display: false,
      },
      leftSidePanel: {
        display: false,
      },
      rightSidePanel: {
        display: false,
      },
    },
  },
  routes: [
    {
      path: 'auth/reset-password/:token',
      element: <ResetPasswordPage />,
    },
  ],
};

export default ResetPasswordConfig;
