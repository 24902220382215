import FuseLoading from '@fuse/core/FuseLoading';

import { FC } from 'react';

const Loading: FC<{ isLoading?: boolean; shouldOverlay?: boolean }> = ({
  isLoading = true,
  shouldOverlay = false,
}) => {
  if (!isLoading) {
    return null;
  }

  const classes = shouldOverlay
    ? 'absolute inset-0 z-50 flex items-center justify-center bg-white bg-opacity-20'
    : '';

  return (
    <div className={classes}>
      <div className="flex items-center justify-center h-full">
        <FuseLoading />
      </div>
    </div>
  );
};

export default Loading;
