import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { ListItemButton, ListItemButtonProps } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { alpha, styled } from '@mui/material/styles';
import clsx from 'clsx';
import { memo } from 'react';
import FuseSvgIcon from '../../../FuseSvgIcon';
import FuseNavBadge from '../../FuseNavBadge';
import { FuseNavItemComponentProps } from '../../FuseNavItem';

type ListItemButtonStyleProps = ListItemButtonProps & {
  itemPadding: number;
};

const Root = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'itemPadding',
})<ListItemButtonStyleProps>(({ theme, itemPadding }) => ({
  minHeight: 44,
  width: '100%',
  borderRadius: '6px',
  margin: '0 0 4px 0',
  paddingRight: 16,
  paddingLeft: itemPadding > 80 ? 80 : itemPadding,
  paddingTop: 10,
  paddingBottom: 10,
  color: alpha(theme.palette.text.primary, 0.7),
  cursor: 'pointer',
  textDecoration: 'none!important',
  '&:hover': {
    color: theme.palette.text.primary,
  },
  '&.active': {
    color: theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, .05)!important'
        : 'rgba(255, 255, 255, .1)!important',
    pointerEvents: 'none',
    transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
    '& > .fuse-list-item-text-primary': {
      color: 'inherit',
    },
    '& > .fuse-list-item-icon': {
      color: 'inherit',
    },
  },
  '& >.fuse-list-item-icon': {
    marginRight: 16,
    color: 'inherit',
  },
  '& > .fuse-list-item-text': {},
}));

/**
 * FuseNavVerticalItem is a React component used to render FuseNavItem as part of the Fuse navigational component.
 */
function FuseNavVerticalItem(props: FuseNavItemComponentProps) {
  const { item, nestedLevel = 0, onItemClick, checkPermission } = props;

  const itemPadding = nestedLevel > 0 ? 38 + nestedLevel * 16 : 16;

  const component = item.url ? NavLinkAdapter : 'li';

  let itemProps = {};

  if (typeof component !== 'string') {
    itemProps = {
      disabled: item.disabled,
      to: item.url || '',
      end: item.end,
      role: 'button',
    };
  }

  if (checkPermission && !item?.hasPermission) {
    return null;
  }

  return (
    <Root
      component={component}
      className={clsx('fuse-list-item', item.active && 'active')}
      onClick={() => onItemClick && onItemClick(item)}
      sx={item.sx}
      itemPadding={itemPadding}
      {...itemProps}
    >
      {item.icon && (
        <FuseSvgIcon
          className={clsx('fuse-list-item-icon shrink-0', item.iconClass)}
          color="action"
        >
          {item.icon}
        </FuseSvgIcon>
      )}

      <ListItemText
        className="fuse-list-item-text"
        primary={item.title}
        secondary={item.subtitle}
        classes={{
          primary: 'text-13 font-medium fuse-list-item-text-primary truncate',
          secondary:
            'text-11 font-medium fuse-list-item-text-secondary leading-normal truncate',
        }}
      />
      {item.badge && <FuseNavBadge badge={item.badge} />}
    </Root>
  );
}

const NavVerticalItem = memo(FuseNavVerticalItem);

export default NavVerticalItem;
