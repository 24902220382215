import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { TUser, useSelector } from 'app/AppStateStore';
import { useAuth } from 'app/AuthProvider';
import { selectUser } from 'app/store/userSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

const UserMenu = () => {
  const { t } = useTranslation('userMenu');
  const user = useSelector<TUser | undefined>(selectUser);
  const { isAuthenticated, signOut } = useAuth();
  const [userMenu, setUserMenu] = useState<HTMLElement | null>(null);

  const userMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
        data-tid="user-avatar"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {user?.displayName}
          </Typography>
          <Typography
            className="text-11 font-medium capitalize"
            color="text.secondary"
          >
            {user?.role && t(`common:${user?.role.toLowerCase()}`)}
            {!user?.role && t('common:guest')}
          </Typography>
        </div>

        {user?.photoURL ? (
          <Avatar
            className="md:mx-4"
            alt={t('profile:user_photo')}
            src={user?.photoURL}
          />
        ) : (
          <Avatar className="md:mx-4">{user?.displayName[0]}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!isAuthenticated ? (
          <>
            <MenuItem
              component={Link}
              to="/auth/sign-in/producer"
              role="button"
            >
              <ListItemIcon className="min-w-40">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <FuseSvgIcon>material-outline:login</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('sign_in_producer')} />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/auth/sign-in/consumer"
              role="button"
            >
              <ListItemIcon className="min-w-40">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <FuseSvgIcon>material-outline:login</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('sign_in_consumer')} />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/auth/sign-up/producer"
              role="button"
            >
              <ListItemIcon className="min-w-40">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <FuseSvgIcon>material-outline:person_add_alt</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('sign_up_producer')} />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/auth/sign-up/consumer"
              role="button"
            >
              <ListItemIcon className="min-w-40">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <FuseSvgIcon>material-outline:person_add_alt</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('sign_up_consumer')} />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              component={Link}
              to="/dashboard"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <FuseSvgIcon>material-outline:dashboard</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('dashboard')} />
            </MenuItem>

            <MenuItem
              component={Link}
              to="/profile"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <FuseSvgIcon>material-outline:account_circle</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('profile')} />
            </MenuItem>

            <MenuItem
              component={Link}
              to="/settings"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <FuseSvgIcon>material-outline:settings</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('settings')} />
            </MenuItem>

            <Divider className="my-8" />

            <MenuItem
              component={NavLink}
              to="/"
              onClick={() => {
                signOut();
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <FuseSvgIcon>material-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('sign_out')} />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
};

export default UserMenu;
