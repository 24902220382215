import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import Logo from 'app/components/shared/Logo';
import Version from 'app/components/shared/Version';
import { useFooterTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { FC, memo } from 'react';
import Copyright from '../../../components/shared/Copyright';
import Apps from './footer/Apps';
import Navigation from './footer/Navigation';
import SocialFollow from './footer/SocialFollow';

const FooterLanding: FC<{ className?: string }> = (props) => {
  const footerTheme = useFooterTheme('dark');

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx('relative z-20 shadow-md', props.className)}
        color="default"
        component="footer"
        sx={{ backgroundColor: footerTheme.palette.background.paper }}
      >
        <Toolbar className="container min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex items-center overflow-x-auto">
          <Grid container direction="column" className="py-12">
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <Logo />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Apps />
              </Grid>
            </Grid>
            <Divider className="my-12" />
            <Grid container justifyContent="space-between">
              <Grid item xs={6} sm={4} className="order-1 sm:order-1">
                <Navigation />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                justifyContent="flex-end"
                className="order-3 self-end pb-4 sm:order-2"
              >
                <Copyright align="center" />
              </Grid>
              <Grid item xs={6} sm={4} className="order-2 sm:order-3 pb-4">
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  className="h-full"
                >
                  <Grid item className="grow-1">
                    <SocialFollow />
                  </Grid>
                  <Grid item>
                    <Version />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default memo(FooterLanding);
