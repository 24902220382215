import { type TPageConfig } from 'app/AppStateStore';
import { lazy } from 'react';

const SignInPage = lazy(() => import('./SignInPage'));

const SignInConfig: TPageConfig = {
  settings: {
    layout: {
      navbar: {
        display: false,
      },
      toolbar: {
        display: false,
      },
      footer: {
        display: false,
      },
      leftSidePanel: {
        display: false,
      },
      rightSidePanel: {
        display: false,
      },
    },
  },
  routes: [
    {
      path: 'auth/sign-in/:role',
      element: <SignInPage />,
    },
  ],
};

export default SignInConfig;
