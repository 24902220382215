import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'app/AppStateStore';
import LanguageSwitcher from 'app/components/shared/LanguageSwitcher';
import NavbarToggleButton from 'app/components/shared/NavbarToggleButton';
import NavigationSearch from 'app/components/shared/NavigationSearch';
import NavigationShortcuts from 'app/components/shared/NavigationShortcuts';
import UserMenu from 'app/components/shared/UserMenu';
import ChatPanelToggleButton from 'app/components/shared/chatPanel/ChatPanelToggleButton';
import NotificationPanelToggleButton from 'app/components/shared/notificationPanel/NotificationPanelToggleButton';
import { selectFuseNavbarOpen } from 'app/store/fuse/navbarSlice';
import {
  selectLayoutConfig,
  useToolbarTheme,
} from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { FC, memo } from 'react';

const ToolbarAdmin: FC<{ className: string } & React.PropsWithChildren> = (
  props
) => {
  const config = useSelector(selectLayoutConfig);
  const navbarOpen = useSelector<boolean>(selectFuseNavbarOpen);
  const toolbarTheme = useToolbarTheme();

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? toolbarTheme.palette.background.paper
              : toolbarTheme.palette.background.default,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden lgDown>
                  {config.navbar.style === 'slide' && !navbarOpen && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            <Hidden lgDown>
              <NavigationShortcuts />
            </Hidden>
          </div>

          <div className="flex items-center pr-16 h-full overflow-x-auto">
            <NavigationSearch />

            <Hidden lgUp>
              <ChatPanelToggleButton />
            </Hidden>

            <NotificationPanelToggleButton />
            <LanguageSwitcher />

            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden lgDown>
                {!navbarOpen && (
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                )}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default memo(ToolbarAdmin);
