import AppBar from '@mui/material/AppBar';
import { styled, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Copyright from 'app/components/shared/Copyright';
import Version from 'app/components/shared/Version';
import { useFooterTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { FC, memo } from 'react';

const FooterAdmin: FC<{ className: string }> = (props) => {
  const footerTheme = useFooterTheme();

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx('relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? footerTheme.palette.background.paper
              : footerTheme.palette.background.default,
        }}
      >
        <ToolBar className="min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex items-center overflow-x-auto">
          <Copyright />
          <Version />
        </ToolBar>
      </AppBar>
    </ThemeProvider>
  );
};

const ToolBar = styled(Toolbar)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export default memo(FooterAdmin);
