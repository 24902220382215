import { Rating } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ProductCardProps {
  id: string;
  title: string;
  imageUrl: string;
  rating: number;
  category: string;
  owner: string;
  description: string;
  createdAt: Date;
  city: {
    name: string;
    id: string;
  };
  delivery: {
    name: string;
    id: string;
  };
}

const ProductCard: FC<ProductCardProps> = ({
  id,
  title,
  imageUrl,
  rating,
  category,
  owner,
  description,
  createdAt,
  city,
  delivery,
}) => {
  const { t } = useTranslation('products');

  return (
    <div data-tid="product-card">
      <Card className="w-full">
        <CardMedia
          className="h-[15rem] w-[27rem] object-cover"
          image={imageUrl}
          title={title}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="truncate h-[5rem] w-[23rem] overflow-hidden whitespace-nowrap"
          >
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Rating name="rating" value={rating} precision={0.5} readOnly />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {category}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {owner}
          </Typography>
          {description && (
            <Typography
              variant="body2"
              color="text.secondary"
              className="truncate h-[5rem] w-[23rem] overflow-hidden whitespace-nowrap"
            >
              {description}
            </Typography>
          )}
          {createdAt && (
            <Typography variant="body2" color="text.primary">
              {createdAt.toLocaleString()}
            </Typography>
          )}
          {city && (
            <Typography variant="body2" color="text.primary">
              {city.name}
            </Typography>
          )}
          {delivery && (
            <Typography variant="body2" color="text.primary">
              {delivery.name}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button size="small" component={Link} to={`/product/${id}`}>
            {t('details')}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};
export default ProductCard;
