import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import { TUser, useDispatch, useSelector } from 'app/AppStateStore';
import { selectUser } from 'app/store/userSlice';
import React, { FC } from 'react';
import { toggleChatPanel } from './store/stateSlice';

const ChatPanelToggleButton: FC<React.PropsWithChildren> = ({
  children = <FuseSvgIcon>heroicons-outline:chat</FuseSvgIcon>,
}) => {
  const dispatch = useDispatch();
  const user = useSelector<TUser | undefined>(selectUser);

  if (!user) {
    return null;
  }

  return (
    <IconButton
      className="w-40 h-40"
      onClick={(ev) => dispatch(toggleChatPanel())}
      size="large"
    >
      {children}
    </IconButton>
  );
};

export default ChatPanelToggleButton;
