import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';

import ProductFilter from 'app/components/shared/search/ProductFilter';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ProductsGrid from '../product/ProductsGrid';

const SearchPage: FC = () => {
  const { t } = useTranslation('products');
  const { search: url } = useLocation();

  const params = new URLSearchParams(url);
  const search = params.get('search') || undefined;
  const city = params.get('city') || undefined;
  const category = params.get('category') || undefined;
  const delivery = params.get('delivery') || undefined;

  return (
    <Root
      header={
        <div className="p-24">
          <h2>{t('product')}</h2>
        </div>
      }
      content={
        <div className="p-24">
          <ProductFilter />
          <ProductsGrid
            search={search}
            city={city}
            category={category}
            delivery={delivery}
          />
        </div>
      }
      scroll="content"
    />
  );
};

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

export default SearchPage;
