import FuseScrollbars from '@fuse/core/FuseScrollbars';
import Divider from '@mui/material/Divider';
import styled from '@mui/material/styles/styled';
import { useDispatch, useSelector } from 'app/AppStateStore';
import { memo, useMemo, useRef } from 'react';
import ContactButton from './ContactButton';
import { getChat } from './store/chatSlice';
import { selectChats } from './store/chatsSlice';
import { selectContacts, selectSelectedContactId } from './store/contactsSlice';
import { openChatPanel } from './store/stateSlice';

const Root = styled(FuseScrollbars)(({ theme }) => ({
  background: theme.palette.background.paper,
}));

const ContactList = () => {
  const dispatch = useDispatch();
  const contacts = useSelector(selectContacts);
  const selectedContactId = useSelector(selectSelectedContactId);
  const chats = useSelector(selectChats);
  const contactListScroll = useRef<HTMLElement>(null);

  const scrollToTop = () => {
    if (contactListScroll.current) {
      contactListScroll.current.scrollTop = 0;
    }
  };

  return (
    <Root
      className="flex shrink-0 flex-col overflow-y-auto py-8 overscroll-contain"
      // @ts-ignore
      ref={contactListScroll}
      option={{ suppressScrollX: true, wheelPropagation: false }}
    >
      {useMemo(() => {
        const chatListContacts =
          contacts.length > 0 && chats.length > 0
            ? chats.map((_chat) => ({
                // @ts-ignore
                ..._chat,
                // @ts-ignore
                ...contacts.find((_contact) => _contact.id === _chat.contactId),
              }))
            : [];

        const handleContactClick = (contactId: string) => {
          // @ts-ignore
          dispatch(openChatPanel());
          // @ts-ignore
          dispatch(getChat(contactId));
          scrollToTop();
        };

        return (
          contacts.length > 0 && (
            <>
              <div className="flex flex-col shrink-0">
                {chatListContacts &&
                  chatListContacts.map((contact) => {
                    return (
                      <div key={contact.id}>
                        <ContactButton
                          contact={contact}
                          selectedContactId={selectedContactId}
                          onClick={handleContactClick}
                        />
                      </div>
                    );
                  })}
                <Divider className="mx-24 my-8" />
                {contacts.map((contact) => {
                  const chatContact = chats.find(
                    // @ts-ignore
                    (_chat) => _chat.contactId === contact.id
                  );

                  return !chatContact ? (
                    // @ts-ignore
                    <div key={contact.id}>
                      <ContactButton
                        contact={contact}
                        selectedContactId={selectedContactId}
                        onClick={handleContactClick}
                      />
                    </div>
                  ) : null;
                })}
              </div>
            </>
          )
        );
      }, [chats, contacts, dispatch, selectedContactId])}
    </Root>
  );
};

export default memo(ContactList);
