import { useSearchCategoriesQuery } from 'generated/graphql-operations';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Error from '../Error';
import Loading from '../Loading';
import CustomSelect from './CustomSelect';

type TCategorySelectProps = {
  value: string;
  onChange: (value: string) => void;
};

const CategorySelect: FC<TCategorySelectProps> = ({ value, onChange }) => {
  const { t } = useTranslation('products');
  const [selectedCategory, setSelectedCategory] = useState(value);

  const { loading, error, data } = useSearchCategoriesQuery();

  useEffect(() => {
    setSelectedCategory(value);
  }, [value]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.categories) {
    return <Error message={t('errorReceiveCategorySelect')} />;
  }

  const categories =
    data?.categories.edges
      ?.map((edge) => edge?.node)
      .filter((node) => node)
      .map((node) => ({
        value: node?.id || '',
        label: node?.name || '',
      })) || [];

  const handleChange = (category: string) => {
    setSelectedCategory(category);
    onChange(category);
  };

  return (
    <div data-tid="category-select">
      <CustomSelect
        label={t('category')}
        value={selectedCategory}
        onChange={(event) => handleChange(event.target.value)}
        options={categories}
      />
    </div>
  );
};

export default CategorySelect;
