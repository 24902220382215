import FuseShortcuts from '@fuse/core/FuseShortcuts';
import { useFlatNavigation } from 'app/core/navigation';
import { FC } from 'react';

const NavigationShortcuts: FC<{ className?: string; variant?: any }> = (
  props
) => {
  const { variant, className } = props;
  // const dispatch = useDispatch();
  // const shortcuts = useSelector(selectUserShortcuts);
  const shortcuts: string[] = [];
  const navigation = useFlatNavigation('all');

  const handleShortcutsChange = (newShortcuts: string[]) => {
    // TODO: this dispatch causes infinite loop in updates, understand why and fix it
    // dispatch(updateUserShortcuts(newShortcuts));
  };

  return (
    <FuseShortcuts
      className={className}
      variant={variant}
      // @ts-ignore
      navigation={navigation}
      shortcuts={shortcuts}
      onChange={handleShortcutsChange}
    />
  );
};

export default NavigationShortcuts;
