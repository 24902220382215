import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { TUser, useDispatch, useSelector } from 'app/AppStateStore';
import { selectUser } from 'app/store/userSlice';
import { FC } from 'react';
import { toggleNotificationPanel } from './store/stateSlice';

const NotificationPanelToggleButton: FC<React.PropsWithChildren> = ({
  children = <FuseSvgIcon>heroicons-outline:bell</FuseSvgIcon>,
}) => {
  // const notifications = useSelector(selectNotifications);
  const notifications = [];
  const user = useSelector<TUser | undefined>(selectUser);

  const dispatch = useDispatch();

  if (!user) {
    return null;
  }

  return (
    <IconButton
      className="w-40 h-40"
      onClick={() => dispatch(toggleNotificationPanel())}
      size="large"
    >
      <Badge
        color="secondary"
        variant="dot"
        invisible={notifications.length === 0}
      >
        {children}
      </Badge>
    </IconButton>
  );
};

export default NotificationPanelToggleButton;
