import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'app/AppStateStore';
import NavbarToggleFab from 'app/components/shared/NavbarToggleFab';
import { selectFuseNavbarOpen } from 'app/store/fuse/navbarSlice';
import {
  selectLayoutConfig,
  useNavbarTheme,
} from 'app/store/fuse/settingsSlice';
import { memo } from 'react';
import NavbarFolded from './navbar/folded/NavbarFolded';
import NavbarSlide from './navbar/slide/NavbarSlide';

const NavbarWrapperAdmin = () => {
  const config = useSelector(selectLayoutConfig);
  const navbarOpen = useSelector<boolean>(selectFuseNavbarOpen);
  const navbarTheme = useNavbarTheme();

  return (
    <>
      <ThemeProvider theme={navbarTheme}>
        <>
          {config.navbar.style === 'slide' && <NavbarSlide />}
          {config.navbar.style === 'folded' && <NavbarFolded />}
        </>
      </ThemeProvider>

      {config.navbar.display && !config.toolbar.display && !navbarOpen && (
        <NavbarToggleFab />
      )}
    </>
  );
};

export default memo(NavbarWrapperAdmin);
