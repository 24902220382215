import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type TNavigationItem = {
  id: string;
  title: string;
  type: 'item' | 'group' | 'collapse';
  url: string;
  icon?: string;
  children?: TNavigationItem[];
};

export const useLandingPageNavigation = () => {
  const { t } = useTranslation();

  const navigation: TNavigationItem[] = useMemo(() => {
    return [
      {
        id: 'for-consumer',
        title: t('landingNavigation:consumer'),
        type: 'item',
        url: 'consumer',
      },
      {
        id: 'for-producer',
        title: t('landingNavigation:producer'),
        type: 'item',
        url: 'producer',
      },
      {
        id: 'recipes',
        title: t('landingNavigation:recipes'),
        type: 'item',
        url: 'recipes',
      },
      {
        id: 'help',
        title: t('landingNavigation:help'),
        type: 'item',
        url: 'help',
      },
      {
        id: 'about',
        title: t('landingNavigation:about'),
        type: 'item',
        url: 'about',
      },
    ];
  }, [t]);

  return navigation;
};

export const useProducerPageNavigation = () => {
  const { t } = useTranslation();

  const navigation: TNavigationItem[] = useMemo(() => {
    return [
      {
        id: 'dashboard',
        title: t('producerNavigation:dashboard'),
        type: 'item',
        icon: 'material-outline:dashboard',
        url: 'dashboard',
      },
      {
        id: 'orders-new',
        title: t('producerNavigation:new_orders'),
        type: 'item',
        icon: 'material-outline:assignment',
        url: 'orders/new',
      },
      {
        id: 'orders-active',
        title: t('producerNavigation:active_orders'),
        type: 'item',
        icon: 'material-outline:update',
        url: 'orders/active',
      },
      {
        id: 'orders-archived',
        title: t('producerNavigation:archived_orders'),
        type: 'item',
        icon: 'material-outline:archive',
        url: 'orders/archived',
      },
      {
        id: 'sales',
        title: t('producerNavigation:sales'),
        type: 'item',
        icon: 'material-outline:monetization_on',
        url: 'my-sales',
      },
      {
        id: 'products',
        title: t('producerNavigation:products'),
        type: 'item',
        icon: 'material-outline:inventory',
        url: 'my-products',
      },
      {
        id: 'recipes',
        title: t('producerNavigation:recipes'),
        type: 'item',
        icon: 'material-outline:microwave',
        url: 'my-recipes',
      },
    ];
  }, [t]);

  return navigation;
};

export const useConsumerPageNavigation = () => {
  const { t } = useTranslation();

  const navigation: TNavigationItem[] = useMemo(() => {
    return [
      {
        id: 'dashboard',
        title: t('consumerNavigation:dashboard'),
        type: 'item',
        icon: 'material-outline:dashboard',
        url: 'dashboard',
      },
      {
        id: 'wish',
        title: t('consumerNavigation:wish'),
        type: 'item',
        icon: 'material-outline:assignment',
        url: 'wish',
      },
      {
        id: 'recommendations',
        title: t('consumerNavigation:recommendations'),
        type: 'item',
        icon: 'material-outline:update',
        url: 'recommendations',
      },
      {
        id: 'map',
        title: t('consumerNavigation:map'),
        type: 'item',
        icon: 'material-outline:archive',
        url: 'map',
      },
      {
        id: 'filter',
        title: t('consumerNavigation:filter'),
        type: 'item',
        icon: 'material-outline:monetization_on',
        url: 'filter',
      },
      {
        id: 'orders',
        title: t('consumerNavigation:orders'),
        type: 'item',
        icon: 'material-outline:inventory',
        url: 'orders',
      },
      {
        id: 'favorites',
        title: t('consumerNavigation:favorites'),
        type: 'item',
        icon: 'material-outline:microwave',
        url: 'favorites',
      },
    ];
  }, [t]);

  return navigation;
};
