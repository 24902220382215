import FuseScrollbars from '@fuse/core/FuseScrollbars';
import styled from '@mui/material/styles/styled';
import Logo from 'app/components/shared/Logo';
import Navigation from 'app/components/shared/Navigation';
import clsx from 'clsx';
import { FC, memo } from 'react';

const NavbarLanding: FC<{ className?: string }> = (props) => {
  return (
    <Root
      className={clsx(
        'w-full h-64 min-h-64 max-h-64 shadow-md',
        props.className
      )}
    >
      <div className="flex flex-auto justify-between items-center w-full h-full container p-0 z-20">
        <div className="flex shrink-0 items-center px-8">
          <Logo />
        </div>

        <FuseScrollbars className="flex h-full items-center">
          <Navigation className="w-full" layout="horizontal" />
        </FuseScrollbars>
      </div>
    </Root>
  );
};

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

export default memo(NavbarLanding);
