import { type TPageConfig } from 'app/AppStateStore';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';
import { lazy } from 'react';

const RecipesPage = lazy(() => import('./RecipesPage'));
const RecipesDetails = lazy(
  () => import('app/components/shared/recipes/RecipesDetails')
);

const RecipesPageConfig: TPageConfig = {
  settings: {
    layout: themeLayoutConfigs['landingLayout'],
  },
  routes: [
    {
      path: 'recipes',
      element: <RecipesPage />,
    },
    {
      path: '/recipes/:id',
      element: <RecipesDetails />,
    },
  ],
};

export default RecipesPageConfig;
