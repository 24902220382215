import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('accessToken');
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'UNAUTHENTICATED':
          // error code is set to UNAUTHENTICATED
          // when AuthenticationError thrown in resolver

          getNewToken().then((payload) => {
            if (!payload) {
              return forward(operation);
            }

            // Modify the operation context with a new token
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `${payload.token_type} ${payload.access_token}`,
              },
            });

            // TODO: This operation works, but doesn't update the related UI, or maybe it doesn't work, I haven't seen second resolver function execution. Ask Vlad why can it happen
            // Retry the request, returning the new observable
            return forward(operation);
          });
      }
    }
  }
});

const getNewToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (!refreshToken) {
    // TODO: replace with logger
    console.warn('No refresh token found');
    return;
  }

  const response = await fetch(`${import.meta.env.VITE_API_URL}connect/token`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `refresh_token=${encodeURIComponent(
      refreshToken
    )}&grant_type=refresh_token&scope=offline_access&client_id=Esti_Web`,
  });

  const accessToken = await response.json();

  localStorage.setItem('accessToken', accessToken.access_token);
  localStorage.setItem('refreshToken', accessToken.refresh_token);
  localStorage.setItem('tokenType', accessToken.token_type);
  localStorage.setItem('expiresIn', accessToken.expires_in);

  return accessToken;
};

const link = ApolloLink.from([
  authLink,
  errorLink,
  new HttpLink({
    // TODO: add URL normalization
    uri: `${import.meta.env.VITE_API_URL}api/graphql`,
    credentials: 'same-origin',
  }),
]);

export const buildClient = () => {
  return new ApolloClient<NormalizedCacheObject>({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      // TODO: This is a temporary solution to avoid caching issues
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};
