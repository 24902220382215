import { type TPageConfig } from 'app/AppStateStore';
import themeLayoutConfigs from 'app/layouts/themeLayoutConfigs';
import { lazy } from 'react';

const ContactsPage = lazy(() => import('./ContactsPage'));

const ContactsPageConfig: TPageConfig = {
  settings: {
    layout: themeLayoutConfigs['landingLayout'],
  },
  routes: [
    {
      path: 'contacts',
      element: <ContactsPage />,
    },
  ],
};

export default ContactsPageConfig;
