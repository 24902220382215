import defaults from 'lodash/fp/defaults';
import uniqueId from 'lodash/fp/uniqueId';

const NotificationModel = (data: object) => {
  data = data || {};

  return defaults(data, {
    id: uniqueId('notification-'),
    icon: 'heroicons-solid:star',
    title: '',
    description: '',
    time: new Date().toISOString(),
    read: false,
    variant: 'default',
  });
};

export default NotificationModel;
